import {html} from './LineProductOptions.html';
import {CartLine} from '../../../models/CartLine';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import VueComponent, {data, method, prop} from '../../../../../core/adapters/VueComponent';

class LineProductOptionsController extends VueComponent {

    @data()
    expandCSV: boolean = false;

    @prop()
    line;

    @prop()
    stacked: boolean;

    @method()
    classesForOption(option) {
        return {
            'csv-overflow': option.option == 'CSV List',
            'line-option-warning': option.warning,
            'position-unset': option.expandCSV,
            'gc-xl-2 gc-md-3 gc-sm-4 gc-xs-6 gc-xxs-12': !this.stacked,
            'gc-xs-12': this.stacked
        }
    }
}

export default function LineProductOptions(): IVueComponent {

    return {
        controller: LineProductOptionsController,
        template: html,
        tag: 'line-product-options'
    };
}
