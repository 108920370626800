import IVueDirective from '../adapters/IVueDirective';

export type DropdownConfigType = {
    stay_open: boolean,
    on_close: () => void;
}

export default function Dropdown(): IVueDirective {
    return {
        directive: {
            beforeMount: function (el, binding, vnode) {
                const root = el;
                const button = el.children[0];
                const list = el.children[1];
                const config = binding.value;

                button.dropdownOnButtonClickEvent = function(event) {
                    let target = event.target as any;

                    if (target?.nodeName == "INPUT") {
                        event.preventDefault();
                        target.focus();
                    }
                    else {
                        root.classList.toggle('open');

                        if (root.classList.contains('open')) {
                            if (button.querySelector('input')) {
                                button.querySelector('input').focus();
                            }
                            else {
                                list.querySelector('.selected')?.focus();
                            }
                        }
                    }
                }
                button.addEventListener('click', button.dropdownOnButtonClickEvent);

                root.clickOutsideEvent = function(event) {
                    // Prevent space bar from activating the button
                    if (event instanceof KeyboardEvent) {
                        if (root.contains(event.target) && event.code == 'Space') {
                            event.preventDefault();
                        }
                    }

                    if (event instanceof MouseEvent) {
                        if (root.classList.contains('open')) {
                            // Clicks outside of the dropdown
                            if (!(root == event.target || root.contains(event.target))) {
                                root.classList.remove('open');
                                config?.on_close();
                            }
                            // Clicks within the dropdown
                            else if (root != event.target && root.children[1].contains(event.target)) {
                                if (!config || !config.stay_open) {
                                    root.classList.remove('open');
                                    config?.on_close();
                                }
                            }
                        }
                    }

                };
                document.body.addEventListener('click', root.clickOutsideEvent);
                document.body.addEventListener('keyup', root.clickOutsideEvent);
            },
            unmounted: function (el) {
                const root = el;
                const button = el.children[0];

                if (button.dropdownOnButtonClickEvent) {
                    button.removeEventListener('click', button.dropdownOnButtonClickEvent);
                }

                if (el.clickOutsideEvent) {
                    document.body.removeEventListener('click', root.clickOutsideEvent);
                    document.body.removeEventListener('keyup', root.clickOutsideEvent);
                }
            },
        },
        tag: 'dropdown'
    }
}