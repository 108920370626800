import {APIResource} from '../../../core/models/APIResource';
import {field} from '../../../core/models/fields/Field';
import {IPromise} from '../../../core/utils/SimplePromise';
import {Services} from '../../../core/services/Services';
import {Http} from '../../../core/services/Http';
import {processRequestError} from '../../../core/utils/utils';
import {CartLine} from './CartLine';

export class SavedCartItem extends CartLine {
    static override uri: string = '/cart/api/user/saved-cart-item/:id/';
    static override objects: APIResource<SavedCartItem>;

    @field()
    date_created;

    @field()
    total_price_gross;

    restoreToCart(): IPromise<any> {
        return Services.get<Http>('$http').request({
            url: '/cart/api/user/restore-to-cart/',
            method: 'POST',
            data: {
                id: this.id
            },
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }).then(() => {

        }, (error) => {
            this._errors = processRequestError(error);
        });
    }
}