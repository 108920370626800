import Cart from './Cart/Cart'
import Item from './CartList/Item/Item'
import LineProductOptions from './CartList/LineProductOptions/LineProductOptions';
import CartCount from './CartCount/CartCount';
import SavedForLaterModal from './SavedForLaterModal/SavedForLaterModal';
import SavedItem from './SavedItem/SavedItem';
import SavedForLater from './SavedForLater/SavedForLater';
import VueApp from '../../../core/adapters/VueApp';

export default function registerComponents(vue_app: VueApp) {
    vue_app.registerComponent(Cart);
    vue_app.registerComponent(Item);
    vue_app.registerComponent(LineProductOptions);
    vue_app.registerComponent(CartCount);
    vue_app.registerComponent(SavedForLaterModal);
    vue_app.registerComponent(SavedItem);
    vue_app.registerComponent(SavedForLater);
}