import {Field} from './Field';

export class PositiveIntegerField extends Field {
    get min(): number {
        return this.config.min || 0;
    }

    get max(): number {
        return this.config.max || Number.MAX_SAFE_INTEGER;
    }

    override clean() {
        let data = this.value;
        if (!data)
            return data;

        else if (typeof(data) === 'string')
            data = parseInt(data);

        if (data < this.min)
            data = this.min;

        if (data > this.max)
            data = this.max;

        return data;
    }

    override validate(): string[] {
        this._errors = super.validate();

        if (this.skipValidation) {
            return this._errors;
        }

        if (this.value < this.min)
            this._errors.push(`Minimum value is ${this.min}`);

        if (this.value > this.max)
            this._errors.push(`Maximum value is ${this.max}`);

        return this._errors;
    }
}
