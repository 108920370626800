import {ChatState} from '../../services/BaseChatService';

export const html:string = `
<div class="chat-header" v-show="chat.state != ${ChatState.IN_CHAT}">
    <img class="chat-header-icon" src="/static/img/chat/site-chat-logo.svg">
    <div class="chat-header-message">
        {{ company.short_name }} Live Chat
    </div>
    <i class="fa fa-chevron-down minimize-icon" aria-hidden="true" @click="chat.minimized = !chat.minimized"></i>
</div>
<div class="chat-header" v-show="chat.state == ${ChatState.IN_CHAT}">
    <div class="image-portal">
        <img v-bind:src="chat.chat_room && chat.chat_room.customer_service_image || '/static/img/chat/site-chat-logo.svg'">
    </div>
    <div class="chat-header-message customer-rep-name">
        {{ chat.chat_room ? chat.chat_room.customer_service_name : '' }}
    </div>
    <i class="fa fa-chevron-down minimize-icon" aria-hidden="true" @click="chat.minimized = !chat.minimized"></i>
</div>
`;