import VueApp from '../../../core/adapters/VueApp';
import {method} from '../../../core/adapters/VueComponent';

export default class ProductFAQListApp extends VueApp {
    @method()
    openQuestion($event) {
        $event.target.closest('.faq-inline-container').querySelector('.answer').classList.toggle('open');
        $event.target.closest('button').classList.toggle('rotated');
    }
}
