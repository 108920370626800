import {ChatState} from '../../services/BaseChatService';

export const html:string = `
<div class="chat-input" v-show="!(chat.chat_disabled || chat.state == ${ChatState.CLOSING})">
    <textarea class="chat-message-input" v-model="chat.chat_input" placeholder="Type a message here..." @keydown.enter.prevent="chat.send()"></textarea>
    <button class="chat-message-submit" @click="chat.send()">
        <i class="fa fa-paper-plane" aria-hidden="true"></i>
    </button>
    <!--
    <button class="chat-message-emoji" ng-click="chat.send()">
        <i class="fa fa-smile" aria-hidden="true"></i>
    </button>
    -->
    <button class="chat-file-upload" @click="chat.in_upload_state = !chat.in_upload_state">
        <i class="fa fa-paperclip" aria-hidden="true"></i>
    </button>
</div>
`;