import VueComponent, {data, method, prop} from '../../../../core/adapters/VueComponent';
import MessageList from '../../../../core/utils/MessageList';
import IVueComponent from '../../../../core/adapters/IVueComponent';

import {html, LoginState, MainMenuStates, SignUpState} from './AccountLogin.html';
import {closeModal} from '../../../../core/utils/Modal';
import ButtonProcessingState from '../../../../core/utils/ButtonProcessingState';
import UserProfileService from '../../../userprofile/services/UserProfileService';
import {Services} from '../../../../core/services/Services';
import {processRequestError} from '../../../../core/utils/utils';

class AccountLoginController extends VueComponent {
    public static override $inject: string[] = [
        '$http',
    ];

    public registeredNames: string[];

    @data()
    errors: any = {};

    @data()
    responses: any = {};

    @data()
    currentState: MainMenuStates = MainMenuStates.REGISTER;

    @data()
    loginRegisterSubState: LoginState = LoginState.HOME;

    @data()
    passwordResetSubState: SignUpState = SignUpState.ENTER_EMAIL;

    @data()
    emailLoginSubState: SignUpState = SignUpState.ENTER_EMAIL;

    @data()
    username_email: string;

    @data()
    password: string;

    @data()
    password_verification: string;

    @data()
    processing_request: boolean;

    @prop()
    modal: boolean;

    constructor(component, protected $http) {
        super(component);

        this.registeredNames = [];
        this.resetStates();

        Services.get<UserProfileService>('UserProfileService').bind('reset-login-modal', () => {
            this.resetStates();
        }, this);
        Services.get<UserProfileService>('UserProfileService').bind('set-register-state', () => {
            this.loginRegisterSubState = LoginState.REGISTER;
            this.$forceUpdate();
        }, this);
    }

    override unmounted() {
        super.unmounted();

        Services.get<UserProfileService>('UserProfileService').unbind('reset-login-modal');
        Services.get<UserProfileService>('UserProfileService').unbind('set-register-state');
    }

    @method()
    public resetStates() {
        this.currentState = MainMenuStates.REGISTER;
        this.loginRegisterSubState = LoginState.HOME;
        this.passwordResetSubState = SignUpState.ENTER_EMAIL;
        this.emailLoginSubState = SignUpState.ENTER_EMAIL;

        Services.get<UserProfileService>('UserProfileService').login_redirect = null;

        //Reset errors and responses
        if (this.registeredNames != null) {
            this.registeredNames.forEach((name) => {
                this.errors[name] = {};
                this.responses[name] = {};
            });
        }

        let url = new URL(window.location.href);
        if (url && url.searchParams) {
            const login_by_email = url.searchParams.get('login-by-email');
            if (login_by_email === 'true') {
                this.currentState = MainMenuStates.LOGIN;
                this.emailLoginSubState = SignUpState.ENTER_EMAIL;
            }
        }
    }

    @method()
    facebookEnabled(): boolean {
        return !!window['facebook_oauth_enabled'];
    }

    @method()
    googleEnabled(): boolean {
        return !!window['google_oauth_enabled'];
    }

    @method()
    appleEnabled() {
        return !!window['apple_oauth_enabled'];
    }

    @method()
    loginUrl(path) {
        let search_params = new URLSearchParams();
        search_params.set('return_url', window.location.pathname + window.location.search);

        return path + '?' + search_params.toString();
    }

    @method()
    public submitLogin($event?) {
        const state = new ButtonProcessingState($event);
        state.process();

        this.handleAjax('/account/api/login/', 'loginForm',  () => {
            state.resolved();
            const next = new URL(window.location.href).searchParams.get('next') || Services.get<UserProfileService>('UserProfileService').login_redirect;

            if (!next && window.location.pathname.indexOf('/account/login/') != -1) {
                //Redirect to home if the user somehow ended up at /account/login/ without a redirect link
                window.location.pathname = '/';
            }

            if (next) {
                let url = new URL(window.location.origin + next);
                window.location.assign(url);
            }
            else if (window.location.pathname.includes('/account/login/')) {
                window.location.pathname = '/';
            }
            else if (window.location.pathname.includes('/checkout/account/')) {
                window.location.pathname = '/checkout/';
            }
            else {
                window.location.reload();
            }
        }, () => {
            state.resolved();
        });
    }

    @method()
    public register($event?) {
        const state = new ButtonProcessingState($event);
        state.process();

        this.handleAjax('/account/api/register/', 'registerForm',  (response) => {
            state.resolved();
            this.loginRegisterSubState = LoginState.COMPLETE;

            if (response.data.authenticated) {
                const next: string = response.data.location || new URL(window.location.href).searchParams.get('next') || Services.get<UserProfileService>('UserProfileService').login_redirect;
                if (next) {
                    let url = new URL(window.location.origin + next);
                    window.location.assign(url);
                }
                else if (window.location.pathname == '/account/login/') {
                    window.location.pathname = '/'
                }
                else if (window.location.pathname.includes('/checkout/account/')) {
                    window.location.pathname = '/checkout/';
                }
                else {
                    window.location.reload();
                }
            }
            else {
                this.responses.registerForm = response.data.message;
            }
        }, (response) => {
            state.resolved();
        });
    }

    @method()
    public cancelRegister() {
        this.loginRegisterSubState = LoginState.HOME;
    }

    @method()
    public resetPassword($event?) {
        const state = new ButtonProcessingState($event);
        state.process();

        this.handleAjax('/account/api/reset-password/', 'resetPasswordForm', () => {
            this.passwordResetSubState = SignUpState.SENT;
            state.resolved();
        }, () => {
            state.resolved();
        });
    }


    @method()
    public emailLogin($event?) {
        const state = new ButtonProcessingState($event);
        state.process();

        this.handleAjax('/account/api/request-login-email/', 'emailLoginForm',  () => {
            state.resolved();
            this.emailLoginSubState = SignUpState.SENT;
        }, () => {
            state.resolved();
        });
    }

    @method()
    close() {
        if (document.getElementById('loginModal')) {
            closeModal('loginModal');
        }
    }

    //Handle the request
    private handleAjax(url, formID, success, on_error) {
        if (this.processing_request) {
            on_error();
            return;
        }
        this.processing_request = true;

        //Register the error and server response field so we can reset it when the modal is closed
        this.registeredNames.push(formID);

        // Use a form for submitting the data
        const form = new FormData()
        form.append('username', this.username_email);
        form.append('email', this.username_email);

        if (formID == 'registerForm' || formID == 'loginForm') {
            form.append('password', this.password);
        }

        if (formID == 'registerForm') {
            form.append('password2', this.password_verification);
        }

        //Get the button so we can disable it during the request to let the user know work is being done and prevent spam
        this.$http.request({
            data: form,
            url: url,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            method: 'POST'
        }).then((response) => {
            this.processing_request = false;
            this.responses[formID] = response.data.message;
            this.errors[formID] = new MessageList();
            success(response);
        }, (error) => {
            this.processing_request = false;
            if (error.response.status === 429) {
                this.errors[formID] = {unexpected: ['Too many attempts, please try again later']};
            }
            else {
                this.errors[formID] = processRequestError(error);
            }
            this.trigger('sync');

            on_error();

            return error;
        });
    }
}

export default function AccountLogin(): IVueComponent {

    return {
        controller: AccountLoginController,
        template: html,
        tag: 'account-login'
    };
}
