import VueComponent, {method, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {html} from './SavedItem.html';
import {SavedCartItem} from '../../models/SavedCartItem';
import ButtonProcessingState from '../../../../core/utils/ButtonProcessingState';
import {Services} from '../../../../core/services/Services';
import CartService from '../../services/CartService';
import {modal} from '../../../../core/utils/Modal';

class SavedItemComponent extends VueComponent {
    @prop()
    line: SavedCartItem;

    @method()
    async removeItem(item: SavedCartItem, $event) {
        await item.delete();

        Services.get<CartService>('CartService').saved_cart_lines.items.removeItemFromID(item.id);

        if (Services.get<CartService>('CartService').saved_cart_lines.items.length == 0) {
            modal.close('saved-for-later-modal');
        }
    }

    @method()
    async addToCart(item: SavedCartItem, $event) {
        let state = new ButtonProcessingState($event);
        state.process();

        await item.restoreToCart().catch(() => {
            state.resolved();
            this.$forceUpdate();
        });

        Services.get<CartService>('CartService').saved_cart_lines.items.removeItemFromID(item.id);
        Services.get<CartService>('CartService').softFetchCartLines();

        if (Services.get<CartService>('CartService').saved_cart_lines.items.length == 0) {
            modal.close('saved-for-later-modal');
        }

        state.resolved();
    }
}

export default function SavedItem(): IVueComponent {
    return {
        controller: SavedItemComponent,
        tag: 'saved-cart-item',
        template: html
    }
}