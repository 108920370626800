import {ChatState} from '../../services/BaseChatService';

export const html:string = `
<div class="chat-component" v-on:dragover="chat.in_upload_state = true">
    <div class="chat-container">
        <audio id="new-message" style="display: none">
            <source src="/static/audio/new-message.mp3" type="audio/mpeg">
            <source src="/static/audio/new-message.ogg" type="audio/ogg">
        </audio>
        
        <chat-header :chat="chat"></chat-header>
        
        <div class="chat-container-content loading-chat-container" v-show="chat.state == ${ChatState.LOADING}">
            <i class="fa fa-spinner fa-spin"></i>
            <div class="loading-message">
                Loading...
            </div>
        </div>
        
        <div class="chat-container-content loading-chat-container" v-show="chat.state == ${ChatState.WAITING_FOR_REP}">
            <i class="fa fa-spinner fa-spin"></i>
            <div class="loading-message">
                Connecting You To A
                <br>
                Customer Service Representative...
            </div>
        </div>
        
        <div class="chat-container-content out-of-office-container" v-show="chat.state == ${ChatState.WAITING_TIMEOUT}">
            <p>
                We are currently working with other 
                <br>
                customers or away from our desk.
            </p>
            <p>
                What would you like to do?
            </p>
            <div class="chat-action-footer double">
                <button class="btn chat-button chat-button-light" @click="chat.ok_waiting = true">
                    <i class="fa fa-clock"></i> Continue Waiting
                </button>
                <button class="btn chat-button chat-button-light" @click="chat.leave_chat()">
                    <i class="fa fa-envelope"></i> Leave Message
                </button>
            </div>
        </div>
        
        <div class="chat-container-content loading-chat-container disconnected-state" v-show="chat.state == ${ChatState.OFFLINE_MESSAGE_SENT}">
            <i class="fa fa-paper-plane"></i>
            <div class="loading-message">
                <h2>Message Sent</h2>
                <p>
                    We will get back to you as soon as we can. 
                    <br>
                    Thank you for your patience.
                </p>
            </div>
            <div class="chat-action-footer">
                <button class="btn chat-button chat-button-light" @click="chat.close_chat()">
                    Close Chat
                </button>
            </div>
        </div>
        
        <div class="chat-container-content loading-chat-container" v-show="chat.state == ${ChatState.RECONNECTING}">
            <i class="fa fa-spinner fa-spin"></i>
            <div class="loading-message">
                Reconnecting...
            </div>
        </div>
        
        <div class="chat-container-content loading-chat-container disconnected-state" v-show="chat.state == ${ChatState.DISCONNECTED}">
            <i class="fa fa-chain-broken"></i>
            <div class="loading-message">
                <h2>Chat - Disconnected</h2>
                <p>It looks like we lost connection with you. Click below to reconnect to chat.</p>
                <button class="btn btn-ghost" @click="chat.reconnect()">Reconnect</button>
            </div>
        </div>
        
        <div class="chat-container-content loading-chat-container" v-show="chat.state == ${ChatState.CHAT_CLOSED}">
            <h2>Thanks for chatting with us!</h2>
            <p>If you need any further help feel free reach out to us again.</p>
        </div>
        
        <div class="chat-container-content out-of-office-container" v-show="chat.state == ${ChatState.OUT_OF_OFFICE}">
            <p>We are currently out of the office.</p>
            <p>
                Our business hours are:
                <br>
                Monday - Friday
                <br>
                7:30am - 4:00pm PST
            </p>
            <p>
                Please leave us a message and we will
                <br>
                get back to you the following business day.
            </p>
            <div class="chat-action-footer">
                <button class="btn chat-button chat-button-light" @click="chat.leaving_message = true">
                    <i class="fa fa-envelope"></i> Leave Message
                </button>
            </div>
        </div>
        
        <div class="chat-container-content" v-show="chat.state == ${ChatState.COLLECTING_INFO}">
            <div class="chat-input-group">
                <label>Name</label>
                <error-list :errors="chat.error_list" :field="'customer-name'"></error-list>
                <input v-model="username">
            </div>
            <div class="chat-input-group">
                <label>Email Address</label>
                <error-list :errors="chat.error_list" :field="'email'"></error-list>
                <input v-model="email">
            </div>
            <div class="chat-input-group">
                <label>What Can We Help You With?</label>
                <error-list :errors="chat.error_list" :field="'description'"></error-list>
                <textarea v-model="description"></textarea>
            </div>
            <div class="chat-action-footer">
                <button class="btn chat-button chat-button-default" @click="chat.updateChatRoom(username, email, description)">Send Message</button>
            </div>
        </div>
        
        <div class="chat-message-container-content" v-show="chat.state == ${ChatState.IN_CHAT} || chat.state == ${ChatState.CLOSING}" id="chat-component">
            <chat-overlay-states :chat="chat"></chat-overlay-states>
            <div id="chat-scroll-area" class="chat-area">
                <div v-for="(item, $index) in chat.history">
                    <div class="chat-message" :class="{ 'owned-by-user': isSelf(item), 'group-message': canGroup(item, $index), 'full-width': item.type == 'chat-event' }">                     
                        <a class="hover-actions" v-if="item.type == 'chat-file' && item.download_ur" :href="item.download_url" target="_blank">
                            <i class="fa fa-download" aria-hidden="true"></i> 
                        </a>
                        
                        <div v-if="item.type == 'chat-line'">
                            <span class="message-owner">{{ item.customer_name }}</span>
                            <span class="message-date">{{ $filters.date(item.date_created, 'HH:mm') }}</span>
                            <div class="chat-bubble">
                                <span class="chat-text">{{ item.message }}</span>
                            </div>
                        </div>
                        <div class="chat-event" v-if="item.type == 'chat-event'">
                            <div class="chat-note" v-if="item.event_slug == 'chat-note'">
                                <label>Staff Note:</label>
                                <p>{{ item.event_data }}</p>
                            </div>
                            <div class="generic-event" v-if="item.event_slug != 'chat-note'">
                                <div v-if="item.display_message">
                                    {{ item.display_message }}
                                </div>
                                <div v-if="!item.display_message">
                                    {{ item.event_data }} {{ item.link_display }} {{ item.event_name }}
                                </div>
                            </div>  
                        </div>
                        <div class="chat-file" v-if="item.type == 'chat-file'">
                            <span class="message-owner">{{ item.customer_name }}</span>
                            <span class="message-date">{{ $filters.date(item.date_created, 'medium') }}</span>
                            <div class="chat-bubble">
                                <span class="filename">{{ item.name }}</span>
                                <img :src="item.preview">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="typing-event" v-for="person in chat.users_typing">
                    {{ person.username }} is typing...
                </div>
            </div>
            <chat-input-area :chat="chat"></chat-input-area>
            <div class="chat-input closing-chat-state" v-show="chat.state == ${ChatState.CLOSING}">
                <div class="closing-message">
                    Are you sure you would like to end this chat?
                </div>
                <button class="btn chat-button chat-button-light" @click="chat.attempting_to_close = false">
                    Cancel
                </button>
                <button class="btn chat-button chat-button-default" @click="chat.leave_chat(); chat.close_chat()">
                    End Chat
                </button>   
            </div>
        </div>
    </div>
</div>
`;