export enum Sign {
    NEGATIVE = -1,
    NONE = 0,
    POSITIVE = 1
}

export class Price {
    /*
     * Simple class that separates a float type value into two parts(integers):
     * dollars and cents. The value of a price object will always have 2
     * decimal places. Currencies that have more or less decimal places are not
     * compatible with this class. It would not take much work for it take a
     * configuration option for the amount of decimal places.
     *
     * This class is mutable, unlike our Python django-prices method of storing
     * currency.
     *
     * Rather than supporting more than 2 decimal places, we could store
     * a currency to convert to that would call the currency conversion API for
     * the exchange rates. Price.value would then return the converted currency
     * value.
     *
     * Storing dollars and cents separately may cause problems. It seems like
     * storing currency in cents is a common way to store currency.
     */
    static zero: Price = new Price('0');

    private _sign: Sign;
    private _dollars: number;
    private _cents: number;

    constructor(price: any) {
        this.value = price;
    }

    add(val: any): Price {
        if (val instanceof Price)
            val = val.value;

        if (val === undefined || val === null)
            val = 0;

        const cents1 = Price.toCents(this.value),
            cents2 = Price.toCents(val),
            parts = Price.centsToDollars(cents1 + cents2);

        return new Price(Price.fromParts(parts[0], parts[1]));
    }

    subtract(val: any): Price {
        if (val instanceof Price)
            val = val.value;

        if (val === undefined || val === null)
            val = 0;

        const cents1 = Price.toCents(this.value),
            cents2 = Price.toCents(val),
            parts = Price.centsToDollars(cents1 - cents2);

        return new Price(Price.fromParts(parts[0], parts[1]));
    }

    multiply(val: any): Price {
        if (val instanceof Price)
            val = val.value;

        if (val === undefined || val === null)
            val = 0;

        const cents = Price.toCents(this.value),
            parts = Price.centsToDollars(Math.round(cents * val));

        return new Price(Price.fromParts(parts[0], parts[1]));
    }

    divide(val: any): Price {
        if (val instanceof Price)
            val = val.value;

        if (val === undefined || val === null)
            val = 0;

        const cents = Price.toCents(this.value);
        const parts = Price.centsToDollars(Math.round(cents / val));

        return new Price(Price.fromParts(parts[0], parts[1]));
    }

    set value(val: any) {
        if (val instanceof Price)
            val = val.value;

        this._sign = Price.sign(val);
        const cents = Price.toCents(val),
            parts = Price.centsToDollars(cents);

        this._dollars = !isNaN(parts[0]) ? parts[0] : 0;
        this._cents = !isNaN(parts[1]) ? parts[1] : 0;
    }

    get value() {
        return Price.fromParts(this._dollars, this._cents);
    }

    set string(val: string) {
        /*
         * Proxy for set value above. Can be used with ng-model='price.string'
         * to pull a string value rather than a float
         * (display 1.50 rather than 1.5)
         */
        this.value = val;
    }

    get string(): string {
        const sign = this._sign == Sign.NEGATIVE ? '-' : '',
            cents = ('' + this._cents).length < 2 ? '0' + this._cents : this._cents;
        return sign + this._dollars + '.' + cents;
    }

    static centsToDollars(cents: any): number[] {
        /*
         * Takes amount in cents and returns dollars and remaining cents
         * Example: 6501 cents would return [65, 1], or 65 dollars and 1 cent
         */
        cents = parseInt(cents);
        const sign = Price.sign(cents),
            roundFunc = sign == Sign.POSITIVE ? Math.floor : Math.ceil,
            dollars = roundFunc(cents / 100);
        cents = cents % 100;
        cents = Price.roundCents(cents);
        return [dollars, cents];
    }

    static roundCents(cents: any): number {
        /*
         * Takes amount in cents and rounds it to 2 decimal places. This function
         * assumes that the value in cents is below 1 dollar.
         */
        cents = '' + cents;
        const sign = Price.sign(cents),
            absCents = cents.indexOf('-') > -1 ? cents.replace('-', '') : cents;

        if (absCents.length > 2) {
            const centsParts = absCents.split('');
            centsParts.splice(2, 0, '.');
            return Math.round(parseFloat(centsParts.join(''))) * sign;
        }
        return parseInt(cents);
    }

    static toParts(val: any): number[] {
        /*
         * Takes a decimal type value and splits it into dollars and cents.
         * Both values have a sign. Example: -1.51 would return an array
         * [-1, -51]
         */
        val += '';
        const parts = val.indexOf('.') > -1 ? val.split('.') : [val, '0'];
        let dollars = parseInt(parts[0]);
        const sign = Price.sign(val) === Sign.NEGATIVE ? Sign.NEGATIVE : Sign.POSITIVE; // Don't want 0 if we have 0 dollars

        // We're dealing with a string, we need to remove it's sign,
        // if present
        let absCents = parts[1].indexOf('-') > -1 ? parts[1].replace('-', '') : parts[1],
            cents;
        if (('' + absCents).length < 2) {
            cents = parseInt(parts[1] + '0');
        } else {
            cents = Price.roundCents(parts[1]);
        }

        // If cents are rounded up to 100+, add to dollars
        if (cents >= 100) {
            dollars += Math.floor(cents / 100);
            cents = cents % 100;
        }

        return [sign * Math.abs(dollars), sign * Math.abs(cents)];
    }

    static fromParts(dollars: any, cents: any): number {
        /*
         * Takes an array of [dollars, cents] and returns a float. This is
         * mainly for display purposes.
         */
        let sign = Sign.POSITIVE,
            signDollars = Price.sign(dollars),
            signCents = Price.sign(cents);

        if (signDollars == signCents) {
            sign = signDollars;
        } else if (signDollars == Sign.NONE) {
            sign = signCents;
        } else {
            sign = signDollars;
        }

        if (sign == Sign.POSITIVE && parseInt(cents) < 10) {
            cents = '0' + Math.abs(cents);
        } else if (sign == Sign.NEGATIVE && parseInt(cents) > -10) {
            cents = '0' + Math.abs(cents);
        } else {
            cents = Math.abs(cents);
        }

        return sign * parseFloat(Math.abs(dollars) + '.' + cents);
    }

    static toCents(val: any): number {
        /*
         * Takes a float type value and returns the full amount in cents.
         * Example: 5.51 would return 551
         */
        const sign = Price.sign(val),
            parts = Price.toParts(val),
            cents = Price.roundCents(parts[1]);

        if (parts[0] === 0) {
            return cents;
        } else {
            return (Math.abs(cents) + Math.abs(parts[0]) * 100) * sign;
        }

    }

    static sign(val: any): number {
        /*
         * return the sign of a value
         */
        val = parseFloat(val);
        return val ? val < Sign.NONE ? Sign.NEGATIVE : Sign.POSITIVE : Sign.NONE;
    }
}
