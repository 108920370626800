import {field} from '../../../core/models/fields/Field';
import {PositiveIntegerField} from '../../../core/models/fields/PositiveNumberField';
import {Model} from '../../../core/models/Model';
import MessageList from '../../../core/utils/MessageList';
import {Collection} from '../../../core/models/Collection';
import {ForeignKeyField} from '../../../core/models/fields/ForeignKeyField';
import {ToManyField} from '../../../core/models/fields/ToManyField';
import {Services} from '../../../core/services/Services';
import {Http} from '../../../core/services/Http';
import {SpecialOffer} from '../../svgcustom/models/SpecialOffer';
import {ConfiguredSpecialOffer} from '../../svgcustom/models/ConfiguredSpecialOffer';
import {APIResource} from '../../../core/models/APIResource';

export class CartLine extends Model {
    static override uri: string = '/cart/api/user/cart-line/:id/';
    static override objects: APIResource<CartLine>;

    private _quote;

    @field()
    data: string;

    @field()
    product_image: string;

    @field()
    product_image_static: string;

    @field()
    product_image_small: string;

    @field()
    product_name: string;

    @field()
    product_sku: string;

    @field()
    product_options: any[];

    @field()
    product_triggers: any[];

    @field()
    product_url: string;

    @field()
    product_edit_url: string;

    @field()
    product_share_url: string;

    @field()
    product_type: string;

    @field(PositiveIntegerField)
    quantity: number;

    @field()
    wholesale_price: number;

    @field()
    wholesale_account: string;

    @field()
    unit_price: number;

    @field()
    additional_flat_price: number;

    @field()
    unit_price_with_discounts: number;

    @field()
    additional_flat_price_with_discounts: number;

    @field()
    bulk_price: number;

    @field()
    line_total: number;

    @field()
    line_total_with_discounts: number;

    @field()
    rushable: boolean;

    @field()
    shipable: boolean;

    @field()
    object_id: number;

    @field()
    defined_quantity: boolean;

    @field()
    default_price: number;

    @field()
    is_user_content: boolean;

    @field()
    minimum_quantity: number;

    @field()
    invert: boolean;

    @field()
    currency: string;

    @field(ToManyField, {
        model: 'SpecialOffer',
        readOnly: true
    })
    available_offers: Collection<SpecialOffer>;

    @field(ToManyField, {
        model: 'ConfiguredSpecialOffer',
        readOnly: true
    })
    special_offers: Collection<ConfiguredSpecialOffer>;

    get size_option() {
        if (!this.product_options) {
            return;
        }

        return this.product_options.find(v => v.option == 'Size');
    }

    get color_option() {
        if (!this.product_options) {
            return;
        }

        return this.product_options.find(v => v.option == 'Color');
    }

    get backordered_option() {
        if (!this.product_options) {
            return;
        }

        return this.product_options.find(v => v.option == 'Backordered');
    }
    get out_of_stock_option() {
        if (!this.product_options) {
            return;
        }

        return this.product_options.find(v => v.option == 'Out of Stock');
    }

    get line_price() {
        return this.line_total_with_discounts
    }

    get quoted_line_price() {
        if (!this._quote) {
            this._quote = this.line_total_with_discounts;
        }

        return this._quote;
    }

    get line_price_without_discounts() {
        return this.line_total
    }

    get has_discounts() {
        return this.line_total != this.line_total_with_discounts;
    }

    override validate(): MessageList {
        let errors = super.validate();

        if (this.quantity < this.minimum_quantity) {
            this.errors.add('quantity', `The minimum quantity for this product is ${this.minimum_quantity}`);
            this.quantity = this.minimum_quantity;
        }

        return errors
    }

    duplicate() {
        return Services.get<Http>('$http').request({
            url: '/cart/api/user/duplicate-line-item/',
            method: 'POST',
            data: {
                item_id: this.id
            }
        });
    }

    removeOffer(offer) {
        Services.get<Http>('$http').request({
            url: '/custom/api/v1/remove-offer/',
            method: 'POST',
            data: {
                offer: offer.id,
                cart_line: this.id,
            }
        }).then(
            (response) => {
                this.reload();
            }, (response) => {
                this.reload();

                return response
            }
        );
    }

    addOffer(offer) {
        Services.get<Http>('$http').request({
            url: '/custom/api/v1/add-offer/',
            method: 'POST',
            data: {
                cart_line: this.id,
                offer: offer.id
            }
        }).then(
            (response) => {
                this.reload();
            },
            (response) => {
                this.reload();

                return response
            }
        );
    }
}
