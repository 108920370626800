export const html: string = `
<div class="mobile-order-detail-container">
    <div class="mobile-order-detail">
        <div class="col-xs-12">
            <h4 class="media-heading">{{ line.product_name }}</h4>
        </div>
        <div class="col-sm-4 col-xs-12 image-area image-group">
            <div class="thumbnail">
                <img class="media-object" v-if="line.is_user_content" :src="line.product_image" :class="{ 'inverted-img' : line.invert }">
                <image-shadow class="media-object" v-if="!line.is_user_content" :image="line.product_image" :invert="line.invert"></image-shadow>
            </div>
        </div>
        <div class="col-sm-5 col-xs-12 line-price-qty-area">
            <table class="full-width">
                <tbody>
                    <tr>
                        <td>
                            <b>Product Sku:</b>
                        </td>
                        <td>
                            {{ line.product_sku }}
                        </td>    
                    </tr>
                    <tr>
                        <td>
                            <b>Quantity:</b>
                        </td>
                        <td>
                            <input type="number" class="quantity form-control" v-model="updateQuantity" :disabled="line.defined_quantity" @change="updateLineItem()">
                        </td>    
                    </tr>
                    <tr v-if="isBulkPrice || isWholesalePrice || isDiscountedPrice || isAccountPrice">
                        <td>
                            <b>Single Price:</b>
                        </td>
                        <td :class="{'line-through': isDiscountedPrice || isBulkPrice || isWholesalePrice || isAccountPrice}">
                            {{ $filters.currency(line.default_price, line.currency) }}
                        </td>    
                    </tr>
                    <tr v-if="isWholesalePrice" v-tooltip tooltip-content="Our Bulk Price reflects the item's quantity price break.">
                        <td>
                            <b>Bulk Unit Price:</b>
                        </td>
                        <td :class="{'line-through': isDiscountedPrice || isBulkPrice || isAccountPrice}">
                            {{ $filters.currency(line.wholesale_price, line.currency) }}
                        </td>    
                    </tr>
                    <tr v-if="isBulkPrice" v-tooltip tooltip-content="Our Bundle Price takes all items with quantities of 25 pieces or more and sets their price at the combined quantity price break.">
                        <td>
                            <b>Bundle Unit Price:</b>
                        </td>
                        <td :class="{'line-through': isDiscountedPrice || isAccountPrice}">
                            {{ $filters.currency(line.bulk_price, line.currency) }}
                        </td>    
                    </tr>
                    <tr v-if="isAccountPrice">
                        <td>
                            <b>{{ line.wholesale_account || 'Wholesale Account' }}</b>
                        </td>
                        <td :class="{'line-through': isDiscountedPrice}">
                            {{ $filters.currency(line.unit_price, line.currency) }}
                        </td>    
                    </tr>
                    <tr v-if="isDiscountedPrice">
                        <td>
                            <b>Discount Unit Price:</b>
                        </td>
                        <td>
                            {{ $filters.currency(line.unit_price_with_discounts, line.currency) }}
                        </td>    
                    </tr>
                    
                    <tr>
                        <td>
                            <b>Total:</b>
                        </td>
                        <td>
                            {{ $filters.currency(line.line_total, line.currency) }}
                        </td>    
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-md-3 col-sm-12">
            <div class="cart-item-options-container vertical">
                <a :href="line.product_edit_url" v-if="line.product_edit_url" class="btn gray-btn btn-sm"><i class="fa fa-edit"></i> Edit</a>
                <a :href="line.product_share_url" v-if="line.product_share_url" class="btn gray-btn btn-sm"><i class="fa fa-share"></i> Share</a>
                <button @click="duplicateLineItem($event)" class="btn gray-btn btn-sm"><i class="fa fa-copy"></i> Duplicate</button>
                <button @click="saveForLater($event)" class="btn gray-btn btn-sm"><i class="fa fa-bookmark"></i> Save For Later</button>
                <button @click="deleteLine($event)" class="btn gray-btn btn-sm"><i class="fa fa-times"></i> Remove</button>
            </div>
        </div>
        
        <div class="col-xs-12 details-area">
            <line-product-options :line="line"></line-product-options>
        </div>
        
        <error-list field="reorder,cart_limit,id,availability,__all__" :errors="line.errors"></error-list>
        <error-list field="reorder,cart_limit,id,availability,__all__" :errors="errors"></error-list>
    </div>
</div>
`;
