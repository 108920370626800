import {Price} from '../../../core/utils/Price';
import {CartLine} from '../models/CartLine';
import {EventDispatcher} from 'simple-ts-event-dispatcher'
import APIResponse from '../../../core/models/APIResponse';
import PagedRequest from '../../../core/models/PagedRequest';
import ButtonProcessingState from '../../../core/utils/ButtonProcessingState';
import {SavedCartItem} from '../models/SavedCartItem';
import {Services} from '../../../core/services/Services';

export default class CartService extends EventDispatcher {
    public lines: APIResponse<CartLine>;

    private discounts: number;
    private _saved_cart_lines: APIResponse<SavedCartItem>;

    constructor() {
        super();
        this.discounts = null;
    }

    fetchCartLines(): APIResponse<CartLine> {
        const lines = this.fetchCart();

        return this.setLinesTo(lines);
    }

    setLinesTo(lines): APIResponse<CartLine> {
        this.lines = lines;
        this.discounts = null;

        return lines;
    }

    get currency() {
        return this.lines.items[0].currency || 'USD';
    }

    private fetchCart() {
        return Services.get<typeof CartLine>('CartLine').objects.all();
    }

    softFetchCartLines(): APIResponse<CartLine> {
        const lines = this.fetchCart();
        lines.$promise.then(() => {
            this.lines = lines;
            this.trigger('sync');
        });
        this.discounts = null;
        return lines;
    }

    deleteLineItem(line, $event) {
        this.discounts = null;

        this.lines.items.removeItemFromID(line.id);
        this.trigger('sync');

        line.delete(() => {
            setTimeout(() => {
                this.softFetchCartLines();
            });
        });
    }

    updateLineItem(line: CartLine, successCallback?, failureCallback?) {
        this.discounts = null;
        line.save().then(() => {
            this.softFetchCartLines();
            if (successCallback)
                successCallback();
        });
    }

    duplicateLineItem(line: CartLine, successCallback?, failureCallback?) {
       line.duplicate().then((response) => {
            if (successCallback)
                successCallback(response);
        }, failureCallback);
    }

    get lineItemTotal() {
        if (this.lines == null) {
            return 0;
        }

        let total = new Price(0);
        for (const line of this.lines.items) {
            total = total.add(new Price(line.unit_price).multiply(line.quantity).add(line.additional_flat_price));
        }
        return total.value;
    }

    get subTotal() {
        if (this.lines == null) {
            return 0;
        }

        let total = new Price(0);
        for (const line of this.lines.items) {
            total = total.add(new Price(line.unit_price_with_discounts).multiply(line.quantity).add(line.additional_flat_price_with_discounts));
        }
        return total.value;
    }

    get lineTotalWithDiscounts() {
        if (this.lines == null)
            return 0;

        let total = new Price(0);
        for (const line of this.lines.items) {
            total = total.add(new Price(line.line_price));
        }
        return total.value;
    }

    priceFlags() {
        //Array represents various modified prices of line items.
        //This is the following key: [Wholesale Price, Bulk Price]
        let priceFlags: number[] = [0, 0];

        if (this.lines == null) {
            return priceFlags;
        }

        for (const line of this.lines.items) {
            if (line.default_price != line.wholesale_price) {
                priceFlags[0] = 1;
            }
            if (line.wholesale_price != line.unit_price) {
                priceFlags[1] = 1;
            }
        }

        return priceFlags;
    }

    get saved_cart_lines() {
        if (!this._saved_cart_lines) {
            this._saved_cart_lines = Services.get<typeof SavedCartItem>('SavedCartItem').objects.all();

            this._saved_cart_lines.$promise.then(() => {
                this.trigger('sync');
            })
        }

        return this._saved_cart_lines;
    }

    reloadSavedItems() {
        this._saved_cart_lines = null;
        return this.saved_cart_lines;
    }
}
