import {html} from './ChatOverlayStates.html';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import VueComponent, {data, method} from '../../../../core/adapters/VueComponent';
import ChatService from '../../services/ChatService';
import {eventToFiles} from '../../../../core/utils/utils';

export class ChatOverlayStatesController extends VueComponent {
    static override $inject = [
        'ChatService'
    ];

    @data()
    chat: ChatService;

    constructor(component, chatService: ChatService) {
        super(component);

        this.chat = chatService;
    }

    @method()
    drop(event) {
        let files = eventToFiles(event);
        this.chat.setupFile(files[0]);
    }
}

export default function ChatOverlayStates(): IVueComponent {

    return {
        controller: ChatOverlayStatesController,
        template: html,
        tag: 'chat-overlay-states'
    };
}
