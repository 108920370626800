import {Model} from '../models/Model';
import {displayKey, object, span} from './utils';

let ModelFormatter = {
    header: function (obj, config) {
        if (!(obj instanceof Model)) {
            return null;
        }

        if (config && config.skip) {
            return null;
        }

        if (!obj.id) {
            return [
                'div',
                span('Model', '#ffc48a'),
                span('<', '#ffc48a'),
                span(`${obj.constructor.name}`, '#d0ff8a'),
                span('>', '#ffc48a')
            ]
        }
        else {
            return [
                'div',
                span('Model', '#ffc48a'),
                span('<', '#ffc48a'),
                span(`${obj.constructor.name}`, '#d0ff8a'),
                span(':', '#ffc48a'),
                span(`${obj.id}`, '#8ac5ff'),
                span('>', '#ffc48a')
            ]
        }
    },
    hasBody: function (obj, config) {
        return true;
    },
    body: function (obj: Model, config) {
        return [
            'div',
            displayKey(obj, 'resource_uri'),
            ...obj.getFields().map(v => displayKey(obj, v)),
            displayKey(obj, '$promise'),
            displayKey(obj, '$resolved'),
            object(obj, {skip: true})]
    }
}

export default ModelFormatter;