import {html} from './ChatHeader.html';
import VueComponent, {data} from '../../../../core/adapters/VueComponent';
import Company from '../../../../core/services/Company';
import IVueComponent from '../../../../core/adapters/IVueComponent';

export class ChatHeaderController extends VueComponent {
    static override $inject = [
        'ChatService',
        'Company'
    ];

    public chat_input: string;
    public element;

    @data()
    chat: any;

    @data()
    company: any;

    constructor(component, ChatService, Company) {
        super(component);

        this.chat = ChatService;
        this.company = Company;
    }
}

export default function ChatHeader(): IVueComponent {

    return {
        controller: ChatHeaderController,
        template: html,
        tag: 'chat-header'
    };
}
