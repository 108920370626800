export const html:string = `
<div class="chat-bubble">
    <chat-component v-if="chat_open" v-show="!chat.minimized" :chat="chat"></chat-component>
    
    <div class="chat-bubble-icon" @click="toggleChat()" v-show="!chat.minimized">
        <i class="far fa-comment" v-if="!chat_open" aria-hidden="true"></i>
        <i class="far fa-times" v-if="chat_open" aria-hidden="true"></i>
    </div>
    
    <div class="chat-bubble-icon" @click="chat.minimized = false" v-show="chat.minimized">
        <i class="far fa-comments" aria-hidden="true"></i>
    </div>
</div>
`;