import {field} from '../../../core/models/fields/Field';
import {ChatItemAbstract} from './ChatItemAbstract';
import {APIResource} from '../../../core/models/APIResource';

export class ChatLine extends ChatItemAbstract {
    static override uri: string = '/chat/api/v1/chat-line/:id/';
    static override objects: APIResource<ChatLine>;
    public type = 'chat-line';

    @field()
    message: string;

    @field()
    edited: boolean;

    @field()
    removed: boolean;

    @field()
    order_input: string;
}