import {TemplateOptionChargeOption} from '../../../../svgcustom/models/TemplateOption';

export const html: string = `
<div class="line-product-options responsive-grid thin-grid">
    <div class="gc-xl-2 gc-md-3 gc-sm-4 gc-xs-6 cart-line-option-item">
        <label>Product ID:</label>
        <span class="detail">{{ line.product_sku }}</span>
    </div>
    <div v-for="option in line.product_options" class="cart-line-option-item" :class="classesForOption(option)">
        
        <label v-if="option.option != 'Proof Approval'">{{ option.option != 'Reverse Cut for Inside Application' ? option.option : 'Reverse Cut' }}:</label> 
        <span v-if="option.option != 'Proof Approval' && option.option != 'Size'" class="detail">
            {{ option.value }}
            <span v-if="option.price"> (+{{ option.price }})</span>
        </span>
        <a v-if="option.option == 'CSV List' && !option.expandCSV && option.value.length > 100" @click="option.expandCSV = true"><br>View More...</a>
        <a v-if="option.option == 'CSV List' && option.expandCSV" @click="option.expandCSV = false"><br>View Less...</a>
        <span v-if="option.option == 'Size'" class="detail">
            {{ option.value }}
        </span>
    </div>
</div>
`;
