import APIResponse from '../models/APIResponse';
import {span, object, displayKey} from './utils';

let ApiResponseFormatter = {
    header: function (obj, config) {
        if (!(obj instanceof APIResponse)) {
            return null;
        }

        if (config && config.skip) {
            return null;
        }

        return [
            'div',
            span('APIResponse', '#ffc48a'),
            span('<', '#ffc48a'),
            span(`${obj.model.name}`, '#d0ff8a'),
            span('>', '#ffc48a')
        ]
    },
    hasBody: function (obj, config) {
        return true;
    },
    body: function (obj, config) {
        return [
            'div',
            displayKey(obj, 'items'),
            displayKey(obj, 'totalCount'),
            displayKey(obj, 'limit'),
            displayKey(obj, 'offset'),
            displayKey(obj, '$promise'),
            displayKey(obj, '$resolved'),
            object(obj, {skip: true})
        ]
    }
}

export default ApiResponseFormatter;