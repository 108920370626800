import {Field, field} from '../../../core/models/fields/Field';
import {ChatItemAbstract} from './ChatItemAbstract';
import {APIResource} from '../../../core/models/APIResource';

export class ChatFile extends ChatItemAbstract {
    static override uri: string = '/chat/api/v1/chat-file/:id/';
    static override objects: APIResource<ChatFile>;
    public type = 'chat-file';

    @field(Field, {
        readOnly: true
    })
    file: string;

    @field(Field, {
        readOnly: true
    })
    preview: string;

    @field()
    name: string

    get download_url() {
        if (!this.id) {
            return null;
        }

        return `/chat/api/download-file/${this.id}/`
    }
}