import {Route} from './RouteService';

export const StorefrontRouteList: Route[] = [
    new Route('marketplace:product', '/products/:categoryPath*/marketplace/:productSlug/'),
    new Route('marketplace:product-edit', '/products/:categoryPath*/marketplace/:productSlug/:configuredId/'),
    new Route('svgcustom:product', '/products/:categoryPath*/product/details/:productSlug/'),
    new Route('svgcustom:product-edit', '/products/:categoryPath*/product/details/:productSlug/:configuredId/'),
    new Route('svgcustom:basic-customize', '/products/:categoryPath*/details/:productSlug/customize/:colorId/'),
    new Route('svgcustom:basic-customize-saved', '/products/:categoryPath*/details/:productSlug/:configuredId/customize/:colorId/'),
    new Route('svgcustom:basic', '/products/:categoryPath*/details/:productSlug/'),
    new Route('svgcustom:basic-edit', '/products/:categoryPath*/details/:productSlug/:configuredId/'),
    new Route('svgcustom:setup', '/products/:categoryPath*/setup/:productSlug/'),
    new Route('svgcustom:setup-edit', '/products/:categoryPath*/setup/:productSlug/:configuredId/'),
    new Route('svgcustom:upload', '/products/:categoryPath*/upload/:productSlug/:configuredId/'),
    new Route('svgcustom:finalize', '/products/:categoryPath*/finalize/:productSlug/:configuredId/'),
    new Route('svgcustom:preview', '/products/:categoryPath*/preview/:productSlug/'),
    new Route('svgcustom:preview-edit', '/products/:categoryPath*/preview/:productSlug/:configuredId/'),
    new Route('svgcustom:personalize', '/products/:categoryPath*/personalize/:productSlug/'),
    new Route('svgcustom:personalize-edit', '/products/:categoryPath*/personalize/:productSlug/:configuredId/'),
    new Route('svgcustom:lettering-new', '/products/:categoryPath*/lettering/:productSlug/'),
    new Route('svgcustom:lettering-edit', '/products/:categoryPath*/lettering/:productSlug/:configuredId/'),
    new Route('svgcustom:customize-any-sticker', '/products/customize-any-sticker/'),
    new Route('svgcustom:ddb', '/products/:categoryPath*/ddb/:productSlug/'),
    new Route('svgcustom:ddb-edit', '/products/:categoryPath*/ddb/:productSlug/:configuredId/'),
    new Route('gallery:list', '/gallery/images/'),
    new Route('gallery:videos', '/gallery/videos/'),
    new Route('gallery:list-pagination', '/gallery/images/:page/'),
    new Route('gallery:list-category-pagination', '/gallery/images/:category/:page/'),
    new Route('gallery:list-products', '/gallery/products/:product/'),
    new Route('gallery:list-products-pagination', '/gallery/products/:product/:page/'),
    new Route('gallery:image', '/gallery/image/:slug/'),
    new Route('gallery:video', '/gallery/video/:slug/'),
    new Route('vectorization', '/vectorization/'),
    new Route('blog:post', '/blog/post/:slug/'),
    new Route('blog:list', '/blog/'),
    new Route('blog:list-pagination', '/blog/:page/'),
    new Route('blog:category-list', '/blog/category/:category/'),
    new Route('blog:category-list-pagination', '/blog/category/:category/:page/'),
    new Route('blog:search-list', '/blog/search/:search/'),
    new Route('blog:search-list-pagination', '/blog/search/:search/:page/'),
    new Route('blog:date-list', '/blog/date/:year/:month/'),
    new Route('blog:date-list-pagination', '/blog/date/:year/:month/:page/'),
    new Route('faq:index', '/faqs/'),
    new Route('faq:category', '/faqs/:category/'),
    new Route('faq:detail', '/faqs/:category/:questionSlug/'),
    new Route('cart:index', '/cart/'),
    new Route('testimonial:index', '/testimonials/'),
    new Route('testimonial:index-pagination', '/testimonials/:page/'),
    new Route('testimonial:category', '/testimonials/:category/'),
    new Route('testimonial:category-pagination', '/testimonials/:category/:page/'),
    new Route('testimonial:detail', '/testimonial/:id/'),
    new Route('testimonials:imageRequest', '/testimonial-image-request/'),
    new Route('checkout:index', '/checkout/'),
    new Route('checkout:thank-you', '/checkout/thank-you/:id/'),
    new Route('gift-cards', '/products/gift-cards/'),
    new Route('materials:white-vinyl', '/sticker-materials/white-vinyl/'),
    new Route('materials:white-repositionable-vinyl', '/sticker-materials/white-repositionable-vinyl/'),
    new Route('materials:front-facing-adhesive', '/sticker-materials/front-facing-adhesive/'),
    new Route('materials:clear-vinyl', '/sticker-materials/clear-vinyl/'),
    new Route('materials:reflective-vinyl', '/sticker-materials/reflective-vinyl/'),
    new Route('materials:permanent-adhesive', '/sticker-materials/permanent-adhesive/'),
    new Route('materials:white-cling', '/sticker-materials/white-cling/'),
    new Route('materials:clear-cling', '/sticker-materials/clear-cling/'),
    new Route('materials:front-facing-cling', '/sticker-materials/front-facing-cling/'),
    new Route('materials:glow-in-the-dark-vinyl', '/sticker-materials/glow-in-the-dark-vinyl/'),
    new Route('materials:perforated-film', '/sticker-materials/perforated-film/'),
    new Route('materials:magnet', '/sticker-materials/magnet/'),
    new Route('svgcustom:product-details', '/storefront/standard-product-details/:productSlug/'),
    new Route('home', '/'),
    new Route('contact-us', '/contact-us/'),
    new Route('colors', '/sticker-colors/'),
    new Route('fonts', '/fonts/'),
    new Route('materials', '/sticker-materials/'),
    new Route('privacy', '/privacy-policy/'),
    new Route('terms', '/terms-and-conditions/'),
    new Route('about', '/about-carstickers/'),
    new Route('instructions', '/sticker-application-instructions/'),
    new Route('instructions:large-transfer', '/sticker-application-instructions/large-transfer-stickers/'),
    new Route('instructions:die-cut', '/sticker-application-instructions/large-die-cut-stickers/'),
    new Route('instructions:windshield', '/sticker-application-instructions/large-windshield-stickers/'),
    new Route('instructions:transfer', '/sticker-application-instructions/transfer-sticker-preparation/'),
    new Route('instructions:family', '/sticker-application-instructions/family-stickers/'),
    new Route('userprofile:account', '/profile/account/'),
    new Route('userprofile:products', '/profile/products/'),
    new Route('userprofile:products-pagination', '/profile/products/'),
    new Route('userprofile:products-folder', '/profile/products/folder/'),
    new Route('userprofile:products-folder-pagination', '/profile/products/folder/'),
    new Route('userprofile:orders', '/profile/orders/'),
    new Route('userprofile:reviews', '/profile/reviews/'),
    new Route('userprofile:orders-pagination', '/profile/orders/:page/'),
    new Route('userprofile:order-details', '/profile/orders/order/:orderId/'),
    new Route('userprofile:order-invoice', '/profile/orders/order/:orderId/invoice/'),
    new Route('userprofile:shipments', '/profile/shipments/'),
    new Route('userprofile:addresses', '/profile/addresses/'),
    new Route('userprofile:credit', '/profile/credit/'),
    new Route('userprofile:nonprofit', '/profile/nonprofit/'),
    new Route('userprofile:proofs', '/profile/proofs/'),
    new Route('userprofile:proof', '/profile/proofs/:proofId/'),
    new Route('search', '/search/:query?/'),
    new Route('search-pagination', '/search/:query/:page/'),
    new Route('marketing:why-buy-from-us', '/why-buy-from-us/'),
    new Route('category:detail', '/products/:categoryPath*/'),
    new Route('nonprofit', '/nonprofit/'),
    new Route('payments:invoice-list', '/payments/invoices/'),
    new Route('payments:invoice-detail', '/payments/invoices/:id/'),
    new Route('userprofile:wholesale', '/profile/wholesale/'),
    new Route('recommended:personalized', '/products/recommended/personalized/'),
    new Route('recommended:category', '/products/recommended/category/'),
    new Route('admin:template', '/admin/svgcustom/template/:id/change/')
];