import {AnalyticsTracking} from '../Analytics';
import {Order} from '../../../apps/order/models/Order';
import APIResponse from '../../models/APIResponse';
import {OrderedItem} from '../../../apps/order/models/OrderedItem';
import * as Sentry from '@sentry/browser';
import {wait} from '../../utils/utils';

export default class VibeTracker implements AnalyticsTracking {
    async vbpxCode() {
        if (navigator && navigator.doNotTrack == '1') {
            return null;
        }

        if (!window['vbpx']) {
            while(!window['vbpx']) {
                await wait(1000);
            }
        }

        return window['vbpx'];
    }

    async trackCheckout(order: Order, ordered_items: APIResponse<OrderedItem>) {
        let vibe = await this.vbpxCode();
        if (!vibe) {
            return;
        }

        vibe('event', 'purchase', {'price_usd': `${order.total_gross}`});
    }

    trackAddedProductToCart(product_id, price, quantity) {}
    trackEvent(category, action, label?, value?) {}
    trackPage(page: string, title?: string) {}
    trackProductPage(sku, default_price) {}
}