import APIResponse from './APIResponse';
import {Model} from './Model';
import {Services} from '../services/Services';
import {Http} from '../services/Http';
import {SimplePromise} from '../utils/SimplePromise';

export interface IAPIResourceCallback extends Function {
    (data: Object): void;
}

export class InvalidURI extends Error {
    public override name = "InvalidURI";
    constructor (message) {
        super(message);
    }
}

type Query = { [key: string]: any }

export class APIResource<T extends Model> {
    static cache = Object.create(null);

    model: any;

    constructor(model) {
        this.model = model;
    }

    private encodedUrl(data, strict=false) {
        // Copy object so we can modify it, this will also ignore all functions
        data = {...data};

        const uri = this.model.uri;
        let parts = uri.split('/');

        let parsed_parts: string[] = [];
        for (const part of parts) {
            if (part.indexOf(':') === 0) {
                let field = part.split(':')[1];

                if (data[field]) {
                    parsed_parts.push(encodeURIComponent(data[field]));
                    delete data[field];
                }
                else if (strict) {
                    throw new InvalidURI('The uri variable was not found in the data');
                }
            }
            else {
                parsed_parts.push(part);
            }
        }
        return {
            url: parsed_parts.join('/'),
            data: {
                params: data
            }
        };
    }

    private get baseUrl() {
        const uri = this.model.uri;
        let parts = uri.split('/');

        let parsed_parts: string[] = [];
        for (const part of parts) {
            if (part.indexOf(':') != 0) {
                parsed_parts.push(part);
            }
        }
        return parsed_parts.join('/');
    }

    public get(params: Query): T {
        const data = this.encodedUrl(params);

        let instance = new this.model();
        const deferred = SimplePromise.defer<null>();

        instance.deferred = deferred;

        Services.get<Http>('$http').get(data.url, data.data).then((response) => {
            if (response.data.objects) {
                if (response.data.objects.length == 0) {
                    deferred.reject('Item not found');
                }
                else {
                    instance.setData(response.data.objects[0])
                }
            }
            else {
                instance.setData(response.data);
            }

            instance.softSave(true);
            instance.trigger('load', this);
            instance.trigger('sync');
            deferred.resolve(instance);
        }, (error) => {
            deferred.reject(error);

            return error;
        })

        return instance;
    }

    public filter(params: Query, cache_key?: string): APIResponse<T> {
        if (cache_key) {
            let cache_value = this.getCache(cache_key);
            if (cache_value) {
                return cache_value;
            }
        }

        let response = new APIResponse<T>(Services.get<Http>('$http').get(this.baseUrl, {params: params}), this.model, params);

        if (cache_key) {
            this.setCache(cache_key, response);
        }

        return response;
    }

    public clearCache(key?) {
        if (key) {
            this.setCache(key, null);
        }
        else {
            APIResource.cache[this.model] = null;
        }
    }

    private getCache(key) {
        if (!this.model.uri) {
            return undefined;
        }

        if (APIResource.cache[this.model.uri] && APIResource.cache[this.model.uri][key]) {
            return APIResource.cache[this.model.uri][key];
        }

        return undefined;
    }

    private setCache(key, value) {
        if (!this.model.uri) {
            return;
        }

        if (!APIResource.cache[this.model.uri]) {
            APIResource.cache[this.model.uri] = Object.create(null);
        }

        APIResource.cache[this.model.uri][key] = value;
    }

    public all(cache?: boolean): APIResponse<T> {
        if (cache) {
            let cache_value = this.getCache('all');
            if (cache_value) {
                return cache_value;
            }
        }

        const data = this.encodedUrl({});
        let response = new APIResponse<T>(Services.get<Http>('$http').get(data.url, data.data), this.model, {});

        if (cache) {
            this.setCache('all', response);
        }

        return response;
    }

    public none() {
        return new APIResponse<T>(null, this.model, {});
    }

    public from(data): APIResponse<T> {
        return new APIResponse<T>(null, this.model, {}, data);
    }

    public delete(params: Query): APIResponse<T> {
        const data = this.encodedUrl(params, true);

        return new APIResponse<T>(Services.get<Http>('$http').delete(data.url), this.model, {});
    }
}
