export function span(str, color) {
    return [
        'span',
        {
            style: `color: ${color}`
        },
        str
    ]
}

export function object(obj, config?) {
    return [
        'object',
        {
            object: obj,
            config: config
        }
    ]
}
export function displayKey(obj, key) {
    return [
        'div',
        span(key, '#81b6e3'),
        span(': ', 'white'),
        obj[key] != undefined ? object(obj[key]) : span('undefined', '#7b7b7b')
    ]
}