import {CartLine} from '../../../models/CartLine';
import CartService from '../../../services/CartService';
import {html} from './Item.html';
import ButtonProcessingState from "../../../../../core/utils/ButtonProcessingState";
import MessageList from '../../../../../core/utils/MessageList';
import IVueComponent from '../../../../../core/adapters/IVueComponent';
import VueComponent, {computed, data, method, prop} from '../../../../../core/adapters/VueComponent';
import {Services} from '../../../../../core/services/Services';
import {Http} from '../../../../../core/services/Http';
import {processRequestError} from '../../../../../core/utils/utils';

class CartListItemController extends VueComponent {
    static override $inject = [
        'CartService',
    ];

    @data()
    errors: MessageList;

    @data()
    updateQuantity: number;

    @prop()
    line: CartLine;

    protected updateTimeout: any;
    protected cartService: CartService;

    constructor(component, cartService: CartService) {
        super(component);
        this.cartService = cartService;

        this.updateQuantity = this.line.quantity
        this.errors = new MessageList()

        this.line.bind('change:quantity', () => {this.updateQuantity = this.line.quantity}, this);
    }

    @method()
    updateLineItem() {
        if (this.updateQuantity) {
            clearTimeout(this.updateTimeout);
            this.updateTimeout = setTimeout(this._updateLineItem.bind(this), 500);
        }
    }

    _updateLineItem() {
        this.line.quantity = parseInt(`${this.updateQuantity}`);
        this.line.validate();

        this.cartService.updateLineItem(this.line, () => {
            this.updateQuantity = this.line.quantity;
        });
    }

    @method()
    duplicateLineItem($event?) {
        const state = new ButtonProcessingState($event);
        state.process();

        this.errors = new MessageList();
        this.cartService.duplicateLineItem(this.line, (response) => {
            state.resolved();
            window.location.replace(response.data.duplicate_item_url);
        }, (error) => {
            this.errors = processRequestError(error);
            state.resolved();
            this.$forceUpdate()
        });
    }

    @method()
    public deleteLine($event: Event): void {
        this.cartService.deleteLineItem(this.line, $event);
    }

    @computed()
    public isAccountPrice() {
        return this.line.bulk_price != this.line.unit_price;
    }

    @computed()
    public isBulkPrice() {
        return this.line.wholesale_price != this.line.bulk_price
    }

    @computed()
    public isWholesalePrice() {
        return this.line.default_price != this.line.wholesale_price;
    }

    @computed()
    public isDiscountedPrice() {
        return this.line.unit_price != this.line.unit_price_with_discounts
    }

    @method()
    saveForLater($event) {
        if (!this.line || !this.line.id) {
            this.errors.add('__all__', 'Unable to save the cart line for later. Please refresh and try again.')
            return;
        }

        let line_instance = this.line;

        let state = new ButtonProcessingState($event);
        state.process();

        Services.get<Http>('$http').request({
            url: '/cart/api/user/save-for-later/',
            method: 'POST',
            data: {
                cart_line: this.line.id
            }
        }).then(async (response) => {
            state.resolved();
            this.cartService.deleteLineItem(line_instance, $event);
            this.cartService.reloadSavedItems();
        }, (error) => {
            this.errors = processRequestError(error);
            state.resolved();
            this.cartService.softFetchCartLines();
        })
    }
}

export default function CartListItem(): IVueComponent {

    return {
        controller: CartListItemController,
        template: html,
        tag: 'cart-list-item'
    };
}
