export const html:string = `
<div class="col-xs-12 content-cell subscribe">
    <h3 class="hidden-xs">Subscribe to our newsletter for exclusive discounts!</h3>
    <h3 class="visible-xs-block">Subscribe to our newsletter</h3>
    <h4 class="visible-xs-block">Get exclusive discounts</h4>
    <div class="input-group">
        <input id="emailInput" type="email" v-model="email" class="form-control" placeholder="Enter Your Email">
        <span class="input-group-btn">
            <button class="btn btn-default subscribe-button" aria-label="Subscribe to Newsletter" @click="subscribe($event)">
                <i class="fa fa-envelope" aria-hidden="true"></i>
                <span v-if="!sent && !processing" class="visible-md-inline visible-lg-inline">Subscribe</span>
                <span v-if="processing" class="visible-md-inline visible-lg-inline">Processing</span>
                <span v-if="sent" class="visible-md-inline visible-lg-inline">Email Sent</span>
            </button>
        </span>
    </div>
    <span class="confirmation-text" v-if="flagShow" v-html="flag"></span>
</div>
`;