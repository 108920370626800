export const html:string = `
<div class="mobile-order-detail">
    <div class="col-xs-12">
        <h4 class="media-heading">{{ line.product_name }}</h4>
    </div>
    <div class="col-sm-3 col-xs-6 image-area image-group">
        <div class="thumbnail">
            <img class="media-object" v-if="line.is_user_content" :src="line.product_image" :class="{ 'inverted-img' : line.invert }">
            <image-shadow class="media-object" v-if="!line.is_user_content" :image="line.product_image" :invert="line.invert"></image-shadow>
        </div>
    </div>
    <div class="col-xs-6 col-full line-price-qty-area">
        <table class="full-width">
            <tbody>
                <tr>
                    <td>
                        <b>Product Sku:</b>
                    </td>
                    <td>
                        {{ line.product_sku }}
                    </td>    
                </tr>
                <tr>
                    <td>
                        <b>Quantity:</b>
                    </td>
                    <td>
                        {{ line.quantity }}
                    </td>    
                </tr>
                <tr>
                    <td>
                        <b>Unit Price:</b>
                    </td>
                    <td>
                        {{ $filters.currency(line.unit_price, line.currency) }}
                    </td>    
                </tr>
                <tr>
                    <td>
                        <b>Total:</b>
                    </td>
                    <td>
                        {{ $filters.currency(line.line_total, line.currency) }}
                    </td>    
                </tr>
            </tbody>
        </table>
    </div>
    <div class="col-sm-3 col-xs-12">
        <div class="cart-item-options-container vertical">
            <button class="btn gray-btn btn-sm" @click.prevent="addToCart(line, $event)">
                <i class="fa fa-shopping-cart"></i> Add To Cart
            </button>
            <button class="btn gray-btn btn-sm" @click.prevent="addToCart(line, $event)">
                <i class="fa fa-trash"></i> Remove
            </button>
        </div>
    </div>
    
    <div class="col-xs-12 details-area">
        <line-product-options :line="line"></line-product-options>
    </div>
    
    <error-list field="reorder,cart_limit,id,availability" :errors="line.errors"></error-list>
</div>
`;