import {copyToClipboard} from '../utils/utils';
import IVueDirective from '../adapters/IVueDirective';

export default function CopyToClipboard(): IVueDirective {
    return {
        directive: {
            beforeMount: function (el, binding, vnode) {
                let key = binding?.value;
                if (!key) {
                    console.error('Copy directive did not receive a id');
                    return;
                }

                el.addEventListener('click', () => {
                    let elementToCopy = document.getElementById(key) as HTMLInputElement;
                    if (!elementToCopy) {
                        console.error('Unable to find element to copy');
                    }

                    switch (elementToCopy.tagName) {
                        case 'INPUT': {
                            copyToClipboard(elementToCopy.value);
                            break;
                        }

                        case 'TEXTAREA': {
                            elementToCopy.select();
                            copyToClipboard(elementToCopy.value);
                            break
                        }

                        default: {
                            copyToClipboard(elementToCopy.value);
                            break;
                        }
                    }

                    el.innerHTML = '<i class="fa fa-check"></i> Copied To Clipboard';
                });
            },
        },
        tag: 'copy-to-clipboard'
    }
}