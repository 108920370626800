import VueApp from '../../adapters/VueApp';
import {computed, data, method} from '../../adapters/VueComponent';
import CarouselController from '../../utils/CarouselController';

export default class CarouselSliderApp extends VueApp {
    @data()
    carousel_controller: CarouselController;

    constructor() {
        super();

        this.carousel_controller = new CarouselController(this);
    }

    override mounted() {
        this.carousel_controller.mounted();
    }

    override unmounted() {
        this.carousel_controller.unmounted();
    }
}