export const html:string = `
<div class="translucent-overlay" v-if="chat.inOverlayState()">&nbsp</div>
<div class="overlay-state" v-if="chat.in_upload_state">
    <i class="fa fa-times-circle close-overlay-state" @click="chat.in_upload_state = null"></i>
    <div class="drop-image-container" @dragover.prevent="" @drop.prevent="drop">
        <i class="fa fa-image"></i>
        <p> Drag file here to upload <br> or </p>
        <div class="form-group user-file-input">
            <input class="btn-ghost" id="chat-file-upload" type="file" @change="drop">
            <label class="btn btn-info" for="proof-customer-file-upload">Choose File</label>
            <br>
            <span v-if="chat.file">{{ chat.file.name }}</span>
        </div>
    </div>
</div>
`;