import VueItem from '../adapters/VueItem';

export default class CarouselController {
    private component: VueItem;
    private _maxRight;
    private _maxLeft;
    private _slider;

    constructor(component) {
        this.component = component;
    }

    // Must be called
    mounted() {
        if (this.slider) {
            this.slider.addEventListener('scroll', this.onScroll.bind(this));

            // Setup initial values and force vue to recalculate styles
            this.onScroll();
        }
        else {
            // Idk why this is happening
            setTimeout(() => {
                this.mounted();
            }, 1000)
        }
    }

    // Must be called
    unmounted() {
        this.slider?.removeEventListener('scroll', this.onScroll.bind(this))
    }

    onScroll() {
        // Watch to see if we have hit the max left or right side and force a vue update so :styles can update
        if (this._maxLeft != this.maxLeft()) {
            this._maxLeft = this.maxLeft();
            this.component.$forceUpdate();
        }
        if (this._maxRight != this.maxLeft()) {
            this._maxRight = this.maxRight();
            this.component.$forceUpdate();
        }
    }

    get slider() {
        if (this._slider) {
            return this._slider;
        }

        this._slider = this.component.$el?.querySelector('ul.carousel-slider');
        return this._slider;
    }

    get sliderOffset() {
        if (window.innerWidth < 1000) {
            return 10;
        }
        return 75;
    }

    scrollSliderLeft() {
        if (!this.slider) {
            return;
        }

        this.slider.scroll({
            left: this.slider.scrollLeft - this.slider.offsetWidth - this.sliderOffset,
            behavior: 'smooth'
        })
    }

    scrollSliderRight() {
        if (!this.slider) {
            return;
        }

        this.slider.scroll({
            left: this.slider.scrollLeft + this.slider.offsetWidth - this.sliderOffset,
            behavior: 'smooth'
        })
    }

    maxLeft() {
        if (!this.slider) {
            return false;
        }

        return this.slider.scrollLeft == 0;
    }

    maxRight() {
        if (!this.slider) {
            return false;
        }

        return (this.slider.scrollWidth - this.slider.clientWidth) == this.slider.scrollLeft;
    }
}