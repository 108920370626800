import {field} from '../../../core/models/fields/Field';
import {ChatItemAbstract} from './ChatItemAbstract';
import {APIResource} from '../../../core/models/APIResource';

export class ChatEvent extends ChatItemAbstract {
    static override uri: string = '/chat/api/v1/chat-event/:id/';
    static override objects: APIResource<ChatEvent>;
    public type = 'chat-event';

    @field()
    event_type: any;

    @field()
    event_data: string;

    @field()
    event_name: string;

    @field()
    link_display: string;

    @field()
    event_slug: string;

    @field()
    display_message: string;
}