import VueApp from '../../../../core/adapters/VueApp';
import {data, method, prop} from '../../../../core/adapters/VueComponent';

export class RotatingBannerApp extends VueApp {
    public total_items: number;
    public swap_timeout: any;

    @data()
    index: number;

    override mounted() {
        super.mounted();

        this.total_items = parseInt(this.$el.parentElement.getAttribute('total-items'));
        this.index = 0;

        this.swap_timeout = setTimeout(() => {
            this.swapAutomation();
        }, 12000);
    }
    
    @method()
    next() {
        this.index = Math.abs((this.index + 1) % this.total_items);
    }

    @method()
    last() {
        this.index =  Math.abs((this.index - 1) % this.total_items);
    }

    @method()
    stopAutomation() {
        if (this.swap_timeout) {
            clearTimeout(this.swap_timeout);
        }
    }

    @method()
    swapAutomation() {
        this.next();

        this.swap_timeout = setTimeout(() => {
            this.swapAutomation();
        }, 7000)
    }
}