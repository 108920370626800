import AccountLogin from './AccountLogin/AccountLogin';
import AccountLoginModal from './AccountLoginModal/AccountLoginModal';
import VueApp from '../../../core/adapters/VueApp';


export default function registerComponents(vue_app: VueApp) {
    // Add all login components to core, it's loaded on every page
    vue_app.registerComponent(AccountLogin);
    vue_app.registerComponent(AccountLoginModal)
}
