import {Model} from "../../../core/models/Model";
import {field} from "../../../core/models/fields/Field";
import {APIResource} from '../../../core/models/APIResource';

export enum CreditTransactionType {
    CREDIT = 0,
    DEBIT = 1
}

export enum CreditActionType {
    STAFF = 0,
    REFUND = 1,
    PURCHASE = 2,
    GIFT_CARD = 3,
    TESTIMONIAL = 4,
    NONPROFIT = 5,
    TRADE_SHOW = 6,
    DONATION = 7,
    MARKETING = 8
}

export class UserInStoreCreditTransaction extends Model {
    static override uri:string = '/profile/api/user/user-in-store-credit-transaction/:id/';
    static override objects: APIResource<UserInStoreCreditTransaction>;

    @field()
    action: CreditActionType;

    @field()
    balance_change: number;

    @field()
    balance_end: number;

    @field()
    balance_start: number;

    @field()
    comment: string;

    @field()
    date_created: string;

    @field()
    transaction_type: CreditTransactionType;

    @field()
    currency: string;

}
