import VueComponent, {data} from '../../../../core/adapters/VueComponent';
import CartService from '../../services/CartService';
import {Services} from '../../../../core/services/Services';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {html} from './SavedForLater.html';

class SavedForLaterController extends VueComponent {

    @data()
    cart_service: CartService;

    constructor(component) {
        super(component);

        this.cart_service = Services.get<CartService>('CartService');
    }
}

export default function SavedForLater(): IVueComponent {
    return {
        controller: SavedForLaterController,
        template: html,
        tag: 'saved-for-later'
    }
}