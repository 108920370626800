import {html} from './ChatBubble.html';
import ChatService from '../../services/ChatService';
import VueComponent, {data, method} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';

export class ChatBubbleController extends VueComponent {
    static override $inject = [
        '$cookies',
        'ChatService',
    ];

    @data()
    chat_open: boolean;

    @data()
    chat: ChatService

    constructor(protected element, protected $cookies, chat_service: ChatService) {
        super(element);

        this.chat = chat_service;

        let has_active_chat = this.$cookies.get('chat_active') == 'true';
        if (has_active_chat) {
            this.chat.connect();
            this.chat_open = true;
        }
        else{
            this.chat_open = false;
        }

        this.chat.bind('close-chat', () => {
            this.chat_open = false;
        })
        this.chat.bind('open-chat', () => {
            this.chat.connect();
            this.chat_open = true;
        });

        for (const item of document.querySelectorAll('[toggle-chat]')) {
            item.addEventListener('click', () => {
                this.toggleChat();
            })
        }
    }

    @method()
    public toggleChat() {
        if (!this.chat.connected) {
            this.chat_open = true;
            return this.chat.connect();
        }

        if (!this.chat.chat_room || this.chat.chat_room.resolved) {
            this.chat.close_chat();
        }

        if (this.chat_open && this.chat.chat_room.opened) {
            this.chat.attempting_to_close = true;
        }
        else {
            this.chat_open = !this.chat_open;

            if (!this.chat_open && !this.chat.chat_room.opened) {
                this.chat.close_chat();
            }
        }
    }
}

export default function ChatBubble(): IVueComponent {

    return {
        controller: ChatBubbleController,
        template: html,
        tag: 'chat-bubble'
    };
}
