import {Services} from '../services/Services';
import UserProfileService from '../../apps/userprofile/services/UserProfileService';

export function openModal(element_id) {
    if (!document.getElementById(element_id)) {
        console.error(`Unable to find modal with id ${element_id} to open. The value should not contain a query selector.`);
        return
    }

    if (!document.getElementById('backdrop')) {
        const backdrop = document.createElement('div');
        backdrop.id = 'backdrop';
        backdrop.classList.add('modal-backdrop');
        backdrop.classList.add('fade');
        backdrop.classList.add('in');
        backdrop.style.display = 'none';
        document.body.appendChild(backdrop);
    }

    document.getElementsByTagName('body')[0].classList.add('modal-open');
    document.getElementById('backdrop').style.display = 'block';

    const el = document.getElementById(element_id);
    el.style.display = 'block';
    el.classList.add('show');

    // Add a even listener for clicking outside of the modal to close it
    if (!el['modalClickOutside']) {
        el['modalClickOutside'] = (event) => {
            if (event.target?.id == element_id) {
                closeModal(element_id);
            }
        }
    }
    el.addEventListener('mousedown', el['modalClickOutside']);
}

export function closeModal(element_id) {
    if (document.getElementById('backdrop')) {
        document.getElementById('backdrop').style.display = 'none';
    }

    document.getElementsByTagName('body')[0].classList.remove('modal-open');

    const el = document.getElementById(element_id);
    el.style.display = 'none';
    el.classList.remove('show');

    // Clean up and remove the click outside event listener
    if (el['modalClickOutside']) {
        el.removeEventListener('click', el['modalClickOutside']);
        el['modalClickOutside'] = null;
    }
}


export const modal = Object.create(null);
    modal['open'] = openModal;
    modal['close'] = closeModal;
    modal['login'] = () => {
        openModal('loginModal');
    }
    modal['register'] = () => {
    openModal('loginModal');
    setTimeout(() => {
        Services.get<UserProfileService>('UserProfileService').trigger('set-register-state');
    })
}

// Global bindings so we can open and close them on demand
window['modal'] = modal;
