import BaseChatService from './BaseChatService';

export default class ChatService extends BaseChatService {
    protected socket_url = '/ws/chat/client/';

    override onSocketOpen() {
        super.onSocketOpen();
        this.sendEvent('log.page', {
            'url': window.location.href
        });
    }
}