import {Model, TModelDefinitionList} from '../../../core/models/Model';
import {ChatLine} from "./ChatLine";
import {ChatEvent} from './ChatEvent';
import {ChatRoom} from './ChatRoom';
import {ChatFile} from './ChatFile';

export default function registerModels(): void {
    const models: TModelDefinitionList = {
        'ChatLine': ChatLine,
        'ChatRoom': ChatRoom,
        'ChatEvent': ChatEvent,
        'ChatFile': ChatFile
    };

    Model.registerModels(models);
}
