import {AnalyticsTracking} from '../Analytics';
import {Order} from '../../../apps/order/models/Order';
import APIResponse from '../../models/APIResponse';
import {OrderedItem} from '../../../apps/order/models/OrderedItem';
import {Services} from '../Services';
import Company from '../Company';

export default class GTagTracking implements AnalyticsTracking {
    get gtag() {
        return window['gtag'];
    }

    trackAddedProductToCart(product_id, price, quantity) {
        if (!this.gtag) {
            return;
        }

        this.gtag('event', 'add_to_cart', {
            items: [
                {
                    id: product_id,
                    price: price,
                    quantity: quantity
                }
            ]
        })
    }

    get dataLayer(): any {
        if (!window['dataLayer']) {
            window['dataLayer'] = []
        }

        return window['dataLayer'];
    }

    addToDataLayer(value: any) {
        if (!this.dataLayer)
            return;

        this.dataLayer.push(value);
    }

    trackCheckout(order: Order, ordered_items: APIResponse<OrderedItem>) {
        if (!this.gtag) {
            return;
        }

        let gtag_items = [];
        for (const item of ordered_items.items) {
            gtag_items.push({
                id: item.product_sku,
                name: item.product_name,
                price: item.unit_price,
                quantity: item.quantity
            });
        }

        this.gtag('event', 'purchase', {
            transaction_id: order.id,
            value: order.total_gross,
            shipping: order.total_shipping,
            tag: 0,
            currency: order.currency,
            affiliation: Services.get<Company>('Company').short_name,
            items: gtag_items
        });

        this.addToDataLayer({
            event: 'orderConfirmation',
            transactionId: order.id,
            transactionType: 'sale',
            transactionAffiliation: Services.get<Company>('Company').short_name,
            transactionTotal: order.total_gross,
            transactionShipping: order.total_shipping,
            transactionCurrency: 'USD',
            transactionProducts: gtag_items
        });
    }

    trackEvent(category, action, label?, value?) {
        if (!this.gtag) {
            return;
        }

        this.gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value
        })
    }

    trackPage(page: string, title?: string) {
        // Automatically done on each page load
    }

    trackProductPage(sku, default_price) {
        if (!this.gtag) {
            return;
        }

        this.gtag('event', 'view_item', {
            items: [
                {
                    id: sku,
                    price: default_price
                }
            ]
        });
    }
}
