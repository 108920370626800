import {html} from './CartCount.html';
import VueComponent, {data, prop} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import CartService from '../../services/CartService';
import {Http} from '../../../../core/services/Http';
import {Services} from '../../../../core/services/Services';

class CartCountController extends VueComponent {
    @prop()
    default;

    @data()
    amount: Number;

    @data()
    cartService: CartService;

    constructor(component) {
        super(component);

        this.amount = this.$props.default;

        this.cartService = Services.get('CartService');
        this.cartService.bind('update_cart', () => {
            this.getAmount();
        })
    }

    getAmount() {
        Services.get<Http>('$http').request({
            method: 'GET',
            url: '/cart/api/user/cart-count/',
        }).then((response) => {
            this.amount = response.data['data'];
        })
    }
}

export default function CartCount(): IVueComponent {
    return {
        controller: CartCountController,
        template: html,
        tag: 'cart-count'
    };
}
