export const html: string = `
<form id="search-form-mobile" action="/products/search/" :style="{'display': open ? 'inline-block' : 'none' }">
    <div class="search-container">
        <input type="text" name="q" />
        <button aria-label="Submit Site Search" class="btn btn-default" type="submit">
            <i class="fa fa-search" aria-hidden="true"></i>
        </button>
    </div>
</form> 
<button aria-label="Toggle Site Search" class="toggle-site-search" @click="toggleSearch()"><i class="fa" :class="{'fa-search': !open, 'fa-times': open}"></i></button>
`;
