import {html} from './MobileSiteSearch.html';
import VueComponent, {data, method} from '../../adapters/VueComponent';
import IVueComponent from '../../adapters/IVueComponent';

class MobileSiteSearchController extends VueComponent {
    @data()
    open: boolean;

    @data()
    query: string;

    constructor(component) {
        super(component);

        this.query = ''
        this.open = false;
    }

    @method()
    toggleSearch() {
        this.open = !this.open;
        for (const item of document.getElementsByClassName('navbar-brand')) {
            (item as any).style['display'] = this.open ? 'none' : 'block';
        }
    }

}

export default function MobileSiteSearch(): IVueComponent {
    return {
        controller: MobileSiteSearchController,
        template: html,
        tag: 'mobile-site-search'
    };
}
