import {html} from './Subscribe.html';
import UserProfileService from '../../../userprofile/services/UserProfileService';
import {Http} from '../../../../core/services/Http';
import VueComponent, {data, method} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';

class SubscribeController extends VueComponent {
    public static override $inject: string[] = [
        '$http',
        'UserProfileService'
    ];

    @data()
    sent: boolean;

    @data()
    processing: boolean;

    @data()
    flag: string;

    @data()
    flagShow: boolean;

    @data()
    email;

    $http: Http;
    ups: UserProfileService;

    constructor(component, $http: Http, ups: UserProfileService) {
        super(component)
        this.$http = $http;
        this.ups = ups;

        this.sent = false;
        this.flagShow = false;
    }

    @method()
    protected subscribe($event): void {
        if (!this.email || this.email.trim() == "") {
            this.flagShow = true;
            this.flag = "Please enter an email."
            return;
        }

        this.processing = true;
        this.$http.request({
            url: '/profile/api/request-subscription/',
            method: 'POST',
            data: {
                email: this.email
            }
        }).then((response) => {
            this.processing = false;
            this.flagShow = true;
            if (!response.data.success) {
                this.flag = response.data.response;
                this.sent = true;
            }
            else {
                this.flag = "A confirmation email has been sent to your inbox.";
                this.sent = false;
            }
        });
    }
}

export default function Subscribe(): IVueComponent {

    return {
        controller: SubscribeController,
        template: html,
        tag: 'subscribe'
    };
}
