import {Model} from '../models/Model';
import SocketEventHandler from './SocketEventHandler';
import APIResponse from '../models/APIResponse';

export default class ModelSyncService extends SocketEventHandler {
    protected socket_url: string = '/ws/staff/model-change-watcher/';

    private models: Model[];
    private queries: APIResponse<any>[];
    private timeout_wait: number;

    private change_sync_queue: any[];
    private processing_queue: boolean;

    constructor() {
        super();

        this.models = [];
        this.queries = [];
        this.change_sync_queue = [];
        this.timeout_wait = 4000;
    }

    bindEvents() {
        this.bind('notify.change', this.notifyModelsOfChange.bind(this));
        this.bind('notify.addition', this.notifyModelsOfAddition.bind(this));
    }

    async process_queue() {
        if (this.processing_queue) {
            return
        }
        this.processing_queue = true;

        while (this.change_sync_queue.length != 0) {
            this.change_sync_queue.pop().onServerChange();
        }

        this.processing_queue = false
    }

    notifyModelsOfChange(event) {
        for (const model of this.models) {
            if (model.id == event.data.object_id && model.content_type_id == event.data.content_type_id) {
                this.change_sync_queue.push(model);
                this.process_queue();
            }
        }
    }

    notifyModelsOfAddition(event) {
        for (const query of this.queries) {
            // Double matching is probably overkill but it could prevent a very rare potential uuid collision
            if (query.uuid == event.data.uuid && query.extra.content_type_id == event.data.content_type_id) {
                query.onServerAddition(event.data.object_id);
            }
        }
    }

    registerModel(model, $scope?) {
        if (!model.id || !model.content_type_id) {
            return;
        }

        this.sendModelChangeSyncEvent(model);

        if ($scope) {
            $scope.$on('$destroy', () => {
                this.deregisterModel(model);
                model = null;  // clean up any potential memory leaks
            })
        }
    }

    private sendModelChangeSyncEvent(model) {
        this.models.push(model);
        this.sendEvent('sync.on.change', {
            object_id: model.id,
            content_type_id: model.content_type_id
        });
    }

    registerQuery(api_response, $scope?) {
        if (!api_response.extra || !api_response.extra.content_type_id) {
            return;
        }

        this.sendQueryChangeEvent(api_response);

        if ($scope) {
            $scope.$on('$destroy', () => {
                this.deregisterQuery(api_response);
                api_response = null;  // clean up any potential memory leaks
            })
        }
    }

    private sendQueryChangeEvent(api_response: APIResponse<any>) {
        this.queries.push(api_response);
        this.sendEvent('sync.on.addition', {
            content_type_id: api_response.extra.content_type_id,
            search_filters: api_response.params,
            uuid: api_response.uuid
        });
    }

    public deregisterModel(model) {
        if (!model) {
            return;
        }

        if (this.models.indexOf(model) != -1) {
            this.models.splice(this.models.indexOf(model), 1);
        }

        this.sendEvent('sync.unbind.change', {
            object_id: model.id,
            content_type_id: model.content_type_id
        });
    }

    public deregisterQuery(api_response) {
        if (!api_response) {
            return;
        }

        if (this.queries.indexOf(api_response) != -1) {
            this.queries.splice(this.queries.indexOf(api_response), 1);
        }

        this.sendEvent('sync.unbind.addition', {
            content_type_id: api_response.extra.content_type_id,
            search_filters: api_response.params
        });
    }

    override onSocketClose(e) {
        super.onSocketClose(e);
        window.setTimeout(() => {
            this.reconnect();

            let _models = this.models;
            let _queries = this.queries;

            // Register everything again. Garbage collection / clean up listeners should still be in place until scope
            // destroy.
            this.models = [];
            this.queries = [];
            for (const item of _models) {
                this.sendModelChangeSyncEvent(item);
            }
            for (const query of _queries) {
                this.sendQueryChangeEvent(query);
            }

        }, this.timeout_wait);

        // Exponential timeout
        this.timeout_wait *= 2;
    }
}