export const html: string = `
<div class="dropdown generic-dropdown" v-dropdown>
    <button class="btn dropdown-toggle dropdown-ghost btn-default" type="button" :class="{'search-dropdown': search}">
        <div v-if="html" class="name text default-display-item" :class="{placeholder: getSelectedName(selected) == placeholder}" v-html="getSelectedName(selected)"></div>
        <div v-if="!html" class="name text default-display-item" :class="{placeholder: getSelectedName(selected) == placeholder}">{{ getSelectedName(selected) }}</div>
        <input type="text" 
            class="dropdown-search-input" 
            v-model="query" 
            v-on:keydown.down="focusFirstListElement($event)" 
            :placeholder="getSelectedName(selected)" 
            v-on:keydown.enter="autofillFromSelected($event)">
           
        <input type="text" 
            class="autocomplete-element-handler" 
            v-model="autocomplete_value"  
            :autocomplete="autocomplete" 
            v-if="autocomplete" 
            aria-hidden="true"
            @change="checkAutocomplete($event)">
            
        <i class="fa fa-chevron-down" aria-hidden="true"></i>
    </button>
    <ul class="dropdown-menu" role="listbox">
        <li v-if="none != null && !hideNone && !noneLast" @click="select(null)" :class="{'selected' : isSelected(null)}" role="option">
            <div class="text">
                <span v-if="html" v-html="getName(null)"></span>
                <span v-if="!html">{{ getName(null) }}</span>
            </div>
        </li>
        <li v-for="item in items" @click="select(item)" :class="{'selected' : isSelected(item)}" role="option" tabindex="0" @keydown.enter="select(item); close();" @keydown.up="tabUp($event)" @keydown.down="tabDown($event)" v-show="display(item)">
            <div class="text">
                <span v-if="html" v-html="getName(item)"></span>
                <span v-if="!html">{{ getName(item) }}</span>
            </div>
        </li>
        <li v-if="none != null && !hideNone && noneLast" @click="select(null)" :class="{'selected' : isSelected(null)}" role="option">
            <div class="text">
                <span v-if="html" v-html="getName(null)"></span>
                <span v-if="!html">{{ getName(null) }}</span>
            </div>
        </li>
        <li v-show="items && items.length > 0 && !anyVisible()" class="no-results-warning">
            <div class="text">
                <span>No Results Found</span>
            </div>
        </li>
    </ul>
</div>
`;
