import {ModalController} from '../../../../core/adapters/ModalComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {html} from './SavedForLaterModal.html';

class SavedForLaterModalController extends ModalController {

    constructor(component) {
        super(component, 'saved-for-later-modal');
    }
}

export default function SavedForLaterModal(): IVueComponent {
    return {
        controller: SavedForLaterModalController,
        tag: 'saved-for-later-modal',
        template: html,
    }
}