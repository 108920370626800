import {Model} from '../../../core/models/Model';
import {Field, field} from '../../../core/models/fields/Field';

export abstract class ChatItemAbstract extends Model {
    abstract type;
    private _date: Date;

    @field()
    date_created: string;

    @field(Field, {
        readOnly: true
    })
    customer_name: string;

    @field(Field, {
        readOnly: true
    })
    user_identifier: string;

    @field()
    is_staff: boolean;

    get date() {
        if (this._date) {
            return this._date;
        }
        this._date = new Date(this.date_created);
        return this._date;
    }
}