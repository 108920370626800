import {CartLine} from '../../models/CartLine';
import CartService from '../../services/CartService';
import {html} from './Cart.html';
import Company from '../../../../core/services/Company';
import ButtonProcessingState from '../../../../core/utils/ButtonProcessingState';
import VueComponent, {computed, data, method} from '../../../../core/adapters/VueComponent';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import {Services} from '../../../../core/services/Services';
import {Http} from '../../../../core/services/Http';
import {modal} from '../../../../core/utils/Modal';

class CartController extends VueComponent {

    @data()
    cart_service: CartService;

    @data()
    company: Company;

    @data()
    loading_text = '';

    @data()
    continue_shopping_url: string = '/';

    constructor(component) {
        super(component);

        this.cart_service = Services.get<CartService>('CartService');
        this.company = Services.get<Company>('Company');

        this.cart_service.fetchCartLines();
        this.cart_service.lines.$promise.then(() => {
            this.loading_text = 'Your cart is empty.'
        });

        Services.get<Http>('$http').request({
            url: '/cart/api/user/last-category/',
            method: 'GET',
        }).then((response) => {
            this.continue_shopping_url = response.data.url || '/';
        })
    }

    @method()
    openSavedForLaterModal($event) {
        modal.open('saved-for-later-modal');
    }
}

export default function Cart(): IVueComponent {
    return {
        controller: CartController,
        template: html,
        tag: 'cart'
    };
}
