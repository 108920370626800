import {createApp, Component, defineAsyncComponent} from 'vue';
import VueComponent from './VueComponent';
import {Services} from '../services/Services';
import IVueComponent from './IVueComponent';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import {DuplicateFilterException} from './Exceptions';
import VueItem from './VueItem';
import IVueDirective from './IVueDirective';
import ClickOutside from '../directives/ClickOutside';
import Dropdown from '../directives/Dropdown';
import NamedRouteService from '../services/NamedRouteService';
import CopyToClipboard from '../directives/CopyToClipboard';
import UserProfileService from '../../apps/userprofile/services/UserProfileService';
import {clamp, kebabize} from '../utils/utils';
import Debug from '../services/Debug';
import * as Sentry from '@sentry/browser';
import Tooltip from '../directives/Tooltip/Tooltip';
import {Model} from '../models/Model';
import APIResponse from '../models/APIResponse';

export default class VueApp extends VueItem {
    protected app;
    private registered_tags: string[] = [];

    constructor() {
        super();

        this.app = this.buildApp();
    }

    protected onComponentCreation(component) {
        this.component = component;
        this.setupDataBindings();
    }

    public static GLOBAL_FILTERS = {
        currency(value: Number|number|string, currency: string) {
            if (value === null || value === undefined || isNaN(Number(value))) {
                return '';
            }

            if (!currency) {
                if (value != 0) {
                    try {
                        console.error(`Monetary value specified without a currency.`);
                        console.trace();
                        throw Error('Invalid Currency')
                    }
                    catch (e) {
                        Sentry.captureException(e);
                    }
                }

                currency = 'USD'
            }

            try {
                return Number(value).toLocaleString(navigator.language, {style: 'currency', currency: currency});
            }
            catch (e) {
                // Fallback for "Incorrect locale information provided"
                // Not sure what causes this error, maybe privacy settings?
                return `${value} ${currency}`;
            }
        },
        date(value, format?) {
            if (!value) {
                return ''
            }

            if (!window['dayjs_initialized']) {
                //Allow localization
                dayjs.extend(localizedFormat);

                window['dayjs_initialized'] = true;
            }

            // AngularJS conversions for named date types to work with days.js
            if (!format) {
                format = 'll';
            }
            else if (format == 'medium') {
                format = 'lll';
            }
            else if (format == 'short') {
                format = 'M/D/YY h:m a';
            }
            else if (format == 'fullDate') {
                format = 'llll';
            }
            else if (format == 'longDate') {
                format = 'LL';
            }
            else if (format == 'mediumDate') {
                format = 'll';
            }
            else if (format == 'shortDate') {
                format = 'l';
            }
            else if (format == 'mediumTime') {
                format = 'LTS';
            }
            else if (format == 'shortTime') {
                format = 'LT';
            }
            else if (format == 'simple') {
                format = 'MMM D'
            }

            return dayjs(value).format(format);
        },
        uppercase(value) {
            if (value === null || value === undefined) {
                return '';
            }
            return String(value).toUpperCase();
        },
        lowercase(value) {
            if (value === null || value === undefined) {
                return '';
            }
            return String(value).toLowerCase();
        },
        floatformat(value, decimal_places?) {
            if (value === null || value === undefined) {
                return '';
            }
            if (decimal_places === null || decimal_places === undefined) {
                decimal_places = 2;
            }

            return Number(value).toFixed(parseInt(decimal_places));
        },
        shortNumber(value) {
            if (value === null || value === undefined) {
                return '';
            }

            let parsed = Number(value);
            if (isNaN(parsed)) {
                return value;
            }

            return Number(value).toString();
        },
        nlToArray(text) {
            return this.strToArray(text, '\n');
        },
        strToArray(text, separator = '\n') {
            if (!text) {
                return []
            }

            let lines = String(text).split(separator);

            // Trim all lines
            lines = lines.map((line) => {
                return line.trim();
            });

            // Remove empty lines
            let idx: number = lines.indexOf('');
            while (idx > -1) {
                lines.splice(idx, 1);
                idx = lines.indexOf('');
            }

            // ng-repeat cannot have duplicates, so we need to make sure every
            // line is unique. Will cause error if duplicates exist.
            // Update: This may no longer be required with vue but lets do it anyway to maintain the same functionality
            const dupes = {};
            for (const line of lines) {
                dupes[line] = (dupes[line] || 0) + 1;
            }

            for (const dupe in dupes) {
                if (dupes[dupe] == 1) {
                    continue;
                }
                for (const idx in lines) {
                    if (lines[idx] != dupe) {
                        continue;
                    }

                    // Add some spaces on the end to make it unique
                    lines[idx] = dupe + Array(dupes[dupe]).join(' ');
                    dupes[dupe] -= 1;
                }
            }

            return lines;
        },
        orderBy(array, key, reversed?) {
            if (!array || !key) {
                return array;
            }

            if (typeof array[Symbol.iterator] !== 'function') {
                console.error('A non iterable object was passed into orderBy')
                console.error(array);
                return [];
            }

            // Copy array so the sort function won't modify the real object
            let array_copy = [];
            for (const item of array) {
                array_copy.push(item);
            }

            array_copy.sort((a,b) => {
                let v = 0
                if (a[key] < b[key]) {
                    v = -1;
                }
                else if (a[key] > b[key]) {
                    v = 1;
                }

                if (reversed) {
                    v *= -1;
                }

                return v;
            })

            return array_copy;
        },
        route(route_name, kwargs) {
            return Services.get<NamedRouteService>('$NamedRouteService').reverse(route_name, kwargs);
        },
        number(num, precision) {
            return Number(num).toFixed(precision);
        },
        convertMeasurement(num, show_unit=false) {
            let converted = Services.get<UserProfileService>('UserProfileService').convertSizeFromInches(num);
            let unit = Services.get<UserProfileService>('UserProfileService').size_notation;

            if (!show_unit) {
                return clamp(converted);
            }

            return `${clamp(converted)}${unit}`
        }
    };

    /*
      Register a async component config

      This allows everything in the async config to be bundled into a single file. The config file should be a
      simple object that maps component tags to classes. All of these tags will need to be defined in the tags
      array.
     */
    registerAsyncConfig(tags: string[], import_function: () => Promise<any>) {
        for (const tag of tags) {
            this.registered_tags.push(tag);
            let async_component = defineAsyncComponent(() => {
                return new Promise((resolve, reject) => {
                    import_function().then((imported) => {
                        let data: IVueComponent = imported.default[tag]();
                        let component: Component = this.buildComponent(data.controller, data.template);
                        resolve(component as any);
                    });
                });
            });

            this.app.component(tag, async_component);
        }
    }

    /*
      Import/register a single async component.

      This will split the component into its own file and load it only when its required.
     */
    registerAsyncComponent(tag: string, import_function: () => Promise<any>) {
        let async_component = defineAsyncComponent(() => {
            return new Promise((resolve, reject) => {
                import_function().then((imported) => {
                    let data: IVueComponent = imported.default();
                    let component: Component = this.buildComponent(data.controller, data.template);
                    resolve(component as any);
                });
            });
        });

        this.app.component(tag, async_component);
    }

    /*
      Register a component into the app core by using a config function.

      This will bundle the component into the core apps file that is loaded on every page.
     */
    public registerComponent(config_function: () => IVueComponent) {
        let data = config_function();
        this.registered_tags.push(data.tag);
        return this.registerComponentManually(data.tag, data.controller, data.template);
    }

    /*
      Register a component manually.

      This will bundle the component into the core apps file that is loaded on every page.
     */
    public registerComponentManually(tag: string, Cls: typeof VueComponent, html: string) {
        this.app.component(tag, this.buildComponent(Cls, html));
    }

    private buildMethods(instance_class, methods={}) {
        if (instance_class.methods_list) {
            for (const method_function_name of instance_class.methods_list) {
                methods[method_function_name] = function (...args) {
                    let function_instance = this.ctrl.findFunctionInstance(method_function_name);

                    if (!function_instance) {
                        console.error(`The registered method with name ${method_function_name} is not a function on the controller ${this.ctrl.constructor.name} or its mixins.`);
                    }

                    return function_instance[method_function_name](...args);
                };
            }
        }

        if (instance_class.mixins) {
            for (const mixin of instance_class.mixins) {
                methods = this.buildMethods(mixin as any, methods);
            }
        }

        return methods;
    }

    private buildPropsList(instance_class, props_list=[]) {
        // todo: Look into proving type hints with props
        // https://v3.vuejs.org/guide/component-props.html#prop-validation

        if (instance_class.props_list) {
            // Only do this for array bindings, we might need to change the api for props to make things uniform
            for (const prop of instance_class.props_list) {
                props_list.push(prop.name);
            }
        }

        if (instance_class.mixins) {
            for (const mixin of instance_class.mixins) {
                props_list = this.buildPropsList(mixin as any, props_list);
            }
        }

        return props_list;
    }

    private buildPropWatchers(instance_class, prop_watchers={}) {
        if (instance_class.props_list) {
            // Only do this for array bindings, we might need to change the api for props to make things uniform
            for (const prop of instance_class.props_list) {
                if (prop.watch) {
                    prop_watchers[prop.name] = function (new_value, old_value) {
                        if (old_value != new_value) {
                            this.ctrl.trigger(`change:${prop.name}`);
                            this.ctrl.trigger('change:props');

                            // todo: it would be nice if we could make this generic so that anything supported could work here without needing to hard code it
                            if (new_value instanceof Model) {
                                if (!new_value.$resolved) {
                                    new_value.$promise.then(this.$forceUpdate.bind(this));
                                }
                            }
                            if (new_value instanceof APIResponse) {
                                if (!new_value.$resolved) {
                                    new_value.$promise.then(this.$forceUpdate.bind(this));
                                }
                            }
                        }
                    }
                }
            }
        }

        if (instance_class.mixins) {
            for (const mixin of instance_class.mixins) {
                prop_watchers = this.buildPropWatchers(mixin as any, prop_watchers);
            }
        }

        return prop_watchers;
    }

    private buildComputed(instance_class, computed={}) {
        if (instance_class.computed_list) {
            for (const computed_function_name of instance_class.computed_list) {
                computed[computed_function_name] = function (...args) {
                    let function_instance = this.ctrl.findFunctionInstance(computed_function_name);

                    if (!function_instance) {
                        console.error(`The registered computed property with name ${computed_function_name} is not a function on the controller ${this.ctrl.constructor.name} or its mixins.`);
                    }

                    return function_instance[computed_function_name](...args);
                };
            }
        }

        if (instance_class.mixins) {
            for (const mixin of instance_class.mixins) {
                computed = this.buildComputed(mixin as any, computed);
            }
        }

        return computed;
    }

    private buildEmits(instance_class, emits=[]) {
        if (instance_class.emits_list) {
            // Only do this for array bindings, we might need to change the api for props to make things uniform
            for (const emit of instance_class.emits_list) {
                // todo: This will need some more work so we can standardize things
                if (emit.indexOf(':') == -1) {
                    emits.push(kebabize(emit));
                }
                else {
                    emits.push(emit);
                }
            }
        }

        if (instance_class.props_list) {
            for (const item of instance_class.props_list) {
                if (item.emit) {
                    if (emits.indexOf(`update:${item.name}`) == -1) {
                        emits.push(`update:${item.name}`);
                    }
                }
            }
        }

        if (instance_class.mixins) {
            for (const mixin of instance_class.mixins) {
                emits = this.buildEmits(mixin as any, emits);
            }
        }

        return emits;
    }


    /*
      Transforms a class based component into a vue component object.
     */
    private buildComponent(Cls: typeof VueComponent, html: string): Component {
        return {
            template: html,
            data() {
                return this.ctrl.data();
            },
            created() {
                this.ctrl.created();
            },
            beforeCreate() {
                // @ts-ignore - Idk why this typing fails here
                this.ctrl = new Cls(this, ...Services.getList(Cls.$inject));
                this.ctrl.beforeCreate();
            },
            beforeMount() {
                this.ctrl.beforeMount();
            },
            mounted() {
                this.ctrl.mounted();
            },
            beforeUpdate() {
                this.ctrl.beforeUpdate();
            },
            updated() {
                this.ctrl.updated();
            },
            activated() {
                this.ctrl.activated();
            },
            deactivated() {
                this.ctrl.deactivated();
            },
            beforeUnmount() {
                this.ctrl.beforeUnmount();
            },
            unmounted() {
                this.ctrl.unmounted();
            },
            errorCaptured(err, instance, info) {
                if (this.ctrl) {
                    return this.ctrl.errorCaptured(err, instance, info);
                }
                else {
                    Sentry.captureException(err);
                    return true;
                }
            },
            renderTracked(e) {
                this.ctrl.renderTracked(e);
            },
            renderTriggered(e) {
                this.ctrl.renderTriggered(e);
            },
            computed: this.buildComputed(Cls),
            methods: this.buildMethods(Cls),
            props: this.buildPropsList(Cls),
            watch: this.buildPropWatchers(Cls),
            emits: this.buildEmits(Cls)
        };
    }

    private buildApp() {
        const app_instance = this;
        const Cls = this.constructor as any;

        return createApp({
            data() {
                return this.ctrl.data();
            },
            created() {
                this.ctrl.created();
            },
            beforeCreate() {
                // @ts-ignore - Idk why this typing fails here
                app_instance.onComponentCreation(this);
                this.ctrl = app_instance;
                this.ctrl.beforeCreate();
            },
            beforeMount() {
                this.ctrl.beforeMount();
            },
            mounted() {
                this.ctrl.mounted();
            },
            beforeUpdate() {
                this.ctrl.beforeUpdate();
            },
            updated() {
                this.ctrl.updated();
            },
            activated() {
                this.ctrl.activated();
            },
            deactivated() {
                this.ctrl.deactivated();
            },
            beforeUnmount() {
                this.ctrl.beforeUnmount();
            },
            unmounted() {
                this.ctrl.unmounted();
            },
            errorCaptured(err, instance, info) {
                if (this.ctrl) {
                    return this.ctrl.errorCaptured(err, instance, info);
                }
                else {
                    Sentry.captureException(err);
                    return true;
                }
            },
            renderTracked(e) {
                this.ctrl.renderTracked(e);
            },
            renderTriggered(e) {
                this.ctrl.renderTriggered(e);
            },
            computed: this.buildComputed(Cls),
            methods: this.buildMethods(Cls),
            props: this.buildPropsList(Cls),
        });
    }

    /*
        Adds a global filter that can be accessed with {{ $filters.foo }} from anywhere.
     */
    public registerFilter(name, func) {
        if (VueApp.GLOBAL_FILTERS[name]) {
            const error_msg = `The filter "${name}" has already been registered.`;
            console.error(error_msg);
            throw new DuplicateFilterException(error_msg);
        }
        VueApp.GLOBAL_FILTERS[name] = func;
    }

    public registerDirective(config_func: () => IVueDirective) {
        const config: IVueDirective = config_func();

        this.app.directive(config['tag'], config['directive']);
    }

    /*
        Mounts the app to a dom element. Once this is done components can no longer be registered to this app.
     */
    public mount(selector: string) {
        if (!document.querySelector(selector)) {
            return;
        }

        // Configure the global filters
        this.app.config.globalProperties.$filters = VueApp.GLOBAL_FILTERS;

        // Register core directives
        this.registerDirective(ClickOutside);
        this.registerDirective(Dropdown);
        this.registerDirective(CopyToClipboard);
        this.registerDirective(Tooltip);

        try {
            let result = this.app.mount(selector);

            if (result &&  Services.isRegistered('Debug')) {
                Services.get<Debug>('Debug').mounted_list.push(selector);
                Services.get<Debug>('Debug').app_component_list[selector] = this.registered_tags;
            }
        }
        catch (e) {
            // vue.esm-bundler.js needs to me modified for this to work.

            // From:
            /*
                const render = (new Function('Vue', code)(runtimeDom));
                render._rc = true;
                return (compileCache[key] = render);
             */

            // To:
            /*
                try {
                    const render = (new Function('Vue', code)(runtimeDom));
                    render._rc = true;
                    return (compileCache[key] = render);
                }
                catch (e) {
                    e.compiled_code = code;
                    throw e;
                }
             */

            let extra_data = null;
            if (e.compiled_code) {
                extra_data = {
                    extra: {
                        code: e.compiled_code
                    }
                }
            }

            Sentry.captureException(e, extra_data);
        }
    }
}
