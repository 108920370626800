import {Model} from '../../../core/models/Model';
import {field} from '../../../core/models/fields/Field';
import {APIResource} from '../../../core/models/APIResource';

export class ChatRoom extends Model {
    static override uri: string = '/chat/api/v1/chat-room/:id/';
    static override objects: APIResource<ChatRoom>;

    @field()
    name: string;

    @field()
    room_id: string;

    @field()
    description: string;

    @field()
    date_created: string;

    @field()
    date_closed: string;

    @field()
    owner: any;

    @field()
    closed: boolean;

    @field()
    opened: boolean;

    @field()
    online: boolean;

    @field()
    resolved: boolean;

    @field()
    customer_name: string;

    @field()
    email: string;

    @field()
    customer_service_image: string;

    @field()
    customer_service_name: string;

    public new_messages: number = 0;
}