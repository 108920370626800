import {EventDispatcher} from 'simple-ts-event-dispatcher';
import {UserAddress} from '../models/UserAddress';
import APIResponse from '../../../core/models/APIResponse';
import {Http} from '../../../core/services/Http';
import {Services} from '../../../core/services/Services';
import {AdminUserAddress} from '../../../../../dashboard/ts/apps/userprofile/models/AdminUserAddress';

export default class UserAddressService extends EventDispatcher {
    public addresses: APIResponse<UserAddress>;
    public countries: string[];

    private fetchingCountries: boolean;
    private statesMap: {};
    private loadedStatesMap: {};
    private assigned_user: number;
    private assigned_guest: number;
    public allow_all: boolean = true;

    constructor() {
        super();
        this.fetchingCountries = false;
        this.statesMap = {};
        this.loadedStatesMap = {};
    }

    public assignUser(id) {
        this.assigned_guest = null;
        if (this.assigned_user != id) {
            this.assigned_user = id;
            this.fetchAddresses(true);
        }
    }

    public assignGuest(id) {
        this.assigned_user = null;
        if (this.assigned_guest != id) {
            this.assigned_guest = id;
            this.fetchAddresses(true);
        }
    }

    public unsetUser() {
        this.assigned_user = null;
        this.fetchAddresses(true);
    }

    public fetchAddresses(reload?) {
        if (!this.addresses || reload) {

            if (this.assigned_user) {
                this.addresses = Services.get<typeof AdminUserAddress>('UserAddress').objects.filter({customer: this.assigned_user, archived: false});
            }
            else if (this.assigned_guest) {
                this.addresses = Services.get<typeof AdminUserAddress>('UserAddress').objects.filter({guest: this.assigned_guest, archived: false});
            }
            else {
                if (this.allow_all) {
                    this.addresses = Services.get<typeof AdminUserAddress>('UserAddress').objects.all();
                }
                else {
                    this.addresses = Services.get<typeof AdminUserAddress>('UserAddress').objects.none()
                }
            }

            this.addresses.$promise.then(() => {
                this.trigger('loaded:addresses');
            });
        }
    }

    public fetchCountries() {
        if (this.countries) {
            return;
        }

        this.countries = [];
        Services.get<Http>('$http').request({
            method: 'GET',
            url: '/delivery/api/get-countries/'
        }).then((response: any) => {
            this.countries = response.data.countries;
            this.trigger('loaded:countries');
            this.trigger('sync');
        }, (error) => {
            this.countries = [];
            return error;
        });
    }

    setDefaultAddress(address, billing: boolean, shipping: boolean) {
        if (!address)
            return;

        Services.get<Http>('$http').request({
            url: '/profile/api/set-default-address/',
            method: 'POST',
            data: {
                address: address.id,
                billing: billing,
                shipping: shipping
            }
        }).then((response) => {
            this.fetchAddresses(true);
        });
    }

    public statesFor(address) {
        if (address) {
            if (!this.statesMap[address.country] && !this.loadedStatesMap[address.country]) {
                this.fetchStates(address.country);
            }

            return this.statesMap[address.country];
        }

        return [];
    }

    public fetchStates(country: string): void {
        if (this.statesMap[country]) {
            return this.statesMap[country]
        }

        this.statesMap[country] = [];
        this.loadedStatesMap[country] = true;

        Services.get<Http>('$http').request({
            method: 'GET',
            url: `/delivery/api/get-states/`,
            params: {
                country: country
            }
        }).then((response: any) => {
            this.statesMap[country] = response.data.states;
            this.trigger('sync');
            this.trigger('loaded:states');
        });
    }
}
