import {field} from '../../../core/models/fields/Field';
import {Model} from '../../../core/models/Model';
import {APIResource} from '../../../core/models/APIResource';

export class CartDiscount extends Model {
    static override uri: string = '/cart/api/user/cart-discount/:id/';
    static override objects: APIResource<CartDiscount>;

    @field()
    discount_amount: number;

    @field()
    name: string;

    @field()
    currency: string;
}
