import {Model, TModelDefinitionList} from '../../../core/models/Model';
import {CartLine} from './CartLine';
import {CartDiscount} from './CartDiscount';
import {SavedCartItem} from './SavedCartItem';

export default function registerModels(): void {
    const models: TModelDefinitionList = {
        'CartDiscount': CartDiscount,
        'CartLine': CartLine,
        'SavedCartItem': SavedCartItem
    };

    Model.registerModels(models);
}