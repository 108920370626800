import VueApp from '../../adapters/VueApp';
import {data} from '../../adapters/VueComponent';

export class DropdownApp extends VueApp {
    @data()
    active: any;

    constructor() {
        super();
    }
}