export const html: string = `
<div class="container-col cart">
    <button class="btn saved-for-later-btn" @click="openSavedForLaterModal($event)" v-show="cart_service.saved_cart_lines.items.length > 0">View Saved Items ({{ cart_service.saved_cart_lines.items.length }})</button>
    
    <div class="col-xs-12 no-padd-xs" v-if="cart_service.lines.items.length > 0">
        <div class="print-only cart-company-logo">
            <img v-bind:src="company.logo" alt="Logo">
        </div>
    
        <div>
            <cart-list-item :line="line" v-for="line in cart_service.lines.items" :key="line.id"></cart-list-item>
        </div>
        <div v-if="cart_service.lines.items.length > 0">
            <div class="col-xs-12 subtotal-group">
                <div v-if="cart_service.priceFlags()[0] == 1 || cart_service.priceFlags()[1] == 1" id="discount_description" class="col-md-4 col-xs-12 hidden-print">
                    <span v-if="cart_service.priceFlags()[0] == 1">Our <strong>Bulk Price</strong> reflects the item's quantity price break.</span><br v-if="cart_service.priceFlags()[0] == 1">
                    <span v-if="cart_service.priceFlags()[1] == 1">Our <strong>Bundle Price</strong> takes all items with quantities of 25 pieces or more and sets their price at the combined quantity price break.</span>
                </div>
                <div class="subtotal">
                    <h2>Subtotal</h2>
                    <span><strong>{{ $filters.currency(cart_service.lineTotalWithDiscounts, cart_service.currency) }}</strong></span>
                </div>
                <div class="continue-actions margin-bttm-30 hidden-print">
                    <a href="/checkout/" class="btn btn-ghost inverted pull-right">Checkout</a>
                    <a :href="continue_shopping_url" class="btn btn-ghost pull-right">Continue Shopping</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-xs-12" v-if="cart_service.lines.items.length == 0">
        <p>{{ loading_text }}</p>
    </div>
</div>
<div class="container-col" id="recommended-products-app">
    <recommended-product-list :cart-list="true"></recommended-product-list>
</div>

<saved-for-later-modal></saved-for-later-modal>
`;
