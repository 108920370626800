import ErrorList from './ErrorList/ErrorList';
import VueApp from '../adapters/VueApp';
import SimpleImageShadow from './SimpleImageShadow/SimpleImageShadow';
import EllipsisText from './EllipsisText/EllipsisText';
import GenericDropdown from './GenericDropdown/GenericDropdown';
import ImageDropdown from './ImageDropdown/ImageDropdown';
import Paginator from './Paginator/Paginator';
import CallbackPaginator from './CallbackPaginator/CallbackPaginator';
import ColorDropdown from './ColorDropdown/ColorDropdown';
import MultiSelectionDropdown from './MultiSelectionDropdown/MultiSelectionDropdown';

export default function registerComponents(vue_app: VueApp) {
    vue_app.registerComponent(ErrorList);
    vue_app.registerComponent(SimpleImageShadow);
    vue_app.registerComponent(EllipsisText);
    vue_app.registerComponent(GenericDropdown);
    vue_app.registerComponent(ImageDropdown);
    vue_app.registerComponent(Paginator);
    vue_app.registerComponent(CallbackPaginator);
    vue_app.registerComponent(ColorDropdown);
    vue_app.registerComponent(MultiSelectionDropdown);
}