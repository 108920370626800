import {html} from './ChatInputArea.html';
import IVueComponent from '../../../../core/adapters/IVueComponent';
import VueComponent, {data, prop} from '../../../../core/adapters/VueComponent';
import ChatService from '../../services/ChatService';

export class ChatInputAreaController extends VueComponent {
    static override $inject = [
        'ChatService',
    ];

    @prop()
    chat: ChatService;

    constructor(component, ChatService) {
        super(component);

        this.chat = ChatService;
    }
}

export default function ChatInputArea(): IVueComponent {

    return {
        controller: ChatInputAreaController,
        template: html,
        tag: 'chat-input-area'
    };
}
